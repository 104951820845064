import Vue from "vue";
import "./gtm";
import "./app.scss";
import App from "./App";
import store from "./store";
import { UiState, GameState } from "./constants";
import Toasted from "vue-toasted";
import VueSocialSharing from "vue-social-sharing";
import VueGtm from "@gtm-support/vue2-gtm";
import DeviceDetector from "device-detector-js";

Vue.use(Toasted);
Vue.use(VueSocialSharing);

Vue.config.productionTip = false;
Vue.prototype.$uiState = UiState;
Vue.prototype.$gameState = GameState;

// Setup GTM
Vue.prototype.$gtmIdPresent = false;
if (process.env.VUE_APP_GTM_ID) {
  Vue.use(VueGtm, {
    id: process.env.VUE_APP_GTM_ID,
    loadScript: process.env.NODE_ENV === "production",
    enabled: true,
    debug:
      process.env.NODE_ENV === "development" ||
      process.env.NODE_ENV === "staging",
  });
  Vue.prototype.$gtmIdPresent = true;
}

// GTM track event mixin
Vue.mixin({
  methods: {
    sendGtmEvent(eventName, additionalData = null) {
      if (
        this.$gtmIdPresent &&
        this.$gtm.enabled() &&
        process.env.NODE_ENV === "production"
      ) {
        const deviceDetector = new DeviceDetector();
        const detected = deviceDetector.parse(navigator.userAgent);

        // Fire GTM event
        this.$gtm.trackEvent({
          event: eventName,
          ...additionalData,
          device_type: `${detected.device.type}`,
          device_os: `${detected.os.name}${detected.os.version}`,
          device_browser: `${detected.client.name}${detected.client.version}`,
        });
        if (
          window.google_tag_manager &&
          window.google_tag_manager[process.env.VUE_APP_GTM_ID]
        ) {
          window.google_tag_manager[
            process.env.VUE_APP_GTM_ID
          ].dataLayer.reset();
        }
      }
    },
  },
});

// TODO: Temporary regarding WebGL development
if (process.env.VUE_APP_DEV_ENV) {
  // WebGL development mode
  console.log("WebGL development mode", process.env.VUE_APP_DEV_ENV);
  new Vue({
    store,
    delimiters: ["${", "}"],
    components: { App },
    el: "#app",
    template: "<App />",
  }).$mount("#app");
} else {
  // Normal mode
  new Vue({
    store,
    delimiters: ["${", "}"],
    components: { App },
  }).$mount("#app");
}

<template>
  <div class="screen screen__loading">
    <div class="logo__wrapper">
      <img class="logo" src="assets/logo--big.png" alt="Cat Genius logo" />
    </div>
    <div class="progress">
      <div class="progress-text">
        <p class="invisible">{{ $trans("introLoading") }}</p>
        <p class="progress-text__inner" :style="`width: ${this.progress}%`">
          {{ $trans("introLoading") }}
        </p>
        <p class="progress-text__outer">{{ $trans("introLoading") }}</p>
      </div>
      <p class="progress__percentage">{{ progress }}%</p>
    </div>
    <div class="progress-bar">
      <div class="progress-bar__inner" :style="`left: ${this.progress}%`"></div>
    </div>
  </div>
</template>

<script>
import * as SoundController from "@/helpers/sounds";

export default {
  name: "LoadingScreen",
  props: ["progress"],
  data() {
    return {
      soundsProgress: 0,
    };
  },
  watch: {
    progress() {
      if (this.progress >= 100 && this.soundsProgress >= 100) {
        this.$emit("finish-loading");
      }
    },
  },
  methods: {
    handleAnimation(anim) {
      this.anim = anim;
      this.anim.setSpeed(this.animationSpeed);
    },
  },
  mounted() {
    this.soundsProgress = SoundController.loadAll();
  },
};
</script>

<style lang="scss">
@import "@/scss/screens/loading-screen.scss";
</style>

import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    currentState: 0, // INIT
    currentGameState: 7, // PAUSED
    // fullscreenState: false,
    soundState: false,
    questionAnswers: [],
    koisWhacked: 0,
  },
  mutations: {},
  actions: {},
  modules: {},
});

import * as THREE from "three";
import { MathUtils } from "three";
import { TWEEN } from 'three/examples/jsm/libs/tween.module.min.js'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
const {SimplexNoise} = require('simplex-noise');


class Fish{

    constructor(isJumpFish){
     //   var material = new THREE.MeshLambertMaterial({ color: 0xff4400 });
      //  const geometry = new THREE.BoxGeometry(0.04,0.04,0.2);

     //   this.mesh = mesh.clone();//new THREE.Mesh(geometry, material);

        this.mesh = null;//new THREE.Mesh( mesh.geometry, mesh.material );

        this.direction;
        this.speed =  Math.random();
        this.lane = 0;
        this.heightAngle = 0;

        this.moveTween = null;
        this.diveTween = null;
        this.scaleTween = null;

        this.isJumpFish = isJumpFish;
        this.depthOffset = Math.random() * 0.5;
        this.extraRotation = 0;
        this.extraRotationTween = null;
        this.baseOrientation = 0;
        this.baseOrientationTarget = 0;

        this.isHit = false;
        
        // dna
        //this.curveNarrowNess = 4;
        this.animationTime;

        // animation
        this.mixer = null;
        this.animations = null;
        this.actionSwim = null;

        this.lastPosition;

        this.simplex = new SimplexNoise();

    }

    loadMesh(modelPath,callback){
        
        const loader = new GLTFLoader();
        var _this = this;
        loader.load(modelPath, function (gltfData) {
            _this.mesh = gltfData.scene;
            let fishScale = 0.04;
            if(!_this.isJumpFish){
                 fishScale = MathUtils.randFloat(0.025,0.043);
            }
            _this.mesh.scale.set(fishScale,fishScale,fishScale);
            _this.animations = gltfData.animations;
            _this.mixer = new THREE.AnimationMixer(_this.mesh);
            _this.actionSwim = _this.mixer.clipAction(THREE.AnimationClip.findByName(_this.animations, "Swim"));
            _this.actionSwim.play();

            callback();
        });

    }

    calculateDepth(value){
        return -0.26-(Math.pow(value,2) -1) * 0.2;
    }

    calculateJump(){
        // const jumpHeight = 0.012;
        // let curve = 0-(Math.pow(value * this.curveNarrowNess,4) -1.6) * jumpHeight;
        // curve = Math.max(curve,-0.1);

        let curve = this.simplex.noise2D(this.mesh.position.x, this.mesh.position.z*0.1);
        curve  *= 1.0;
        curve = Math.min(curve,-0.05);
    
        return curve;
    }

    update(){
        this.baseOrientation += (this.baseOrientationTarget - this.baseOrientation) * 0.03;
    }


    hit(){

        const _this = this;
        this.isHit = true;

        // // SCALE
        // this.scaleTween = new TWEEN.Tween(this.mesh.scale)
        // .to(
        //     { x: 2, y: 1, z: 2 }
        //     ,
        //     300
        // )
        // .easing(TWEEN.Easing.Back.Out)
        // .start()
        // .onComplete(() => {
        
        //         this.scaleTween.stop();
        //         this.scaleTween = new TWEEN.Tween(this.mesh.scale)
        //         .to(
        //             { x:1, y: 1, z: 1 }
        //             ,
        //             300
        //         )
        //         .easing(TWEEN.Easing.Quartic.Out)
        //         .start()
        
        
        // });


         // extra rotation
         this.extraRotation = 1.4;
        //  this.extraRotationTween = new TWEEN.Tween(this)
        //  .to(
        //      { extraRotation: Math.PI *2 },2600
        //  )
        //  //.easing(TWEEN.Easing.Circular.Out)
        //  .start();
    


        // GO down
        this.diveTween = new TWEEN.Tween(this)
        .to(
            { heightAngle: 0 }
            ,
            300
        )
        .easing(TWEEN.Easing.Quartic.Out)
        .start()
        .onUpdate(() => {
            this.mesh.position.y = _this.calculateDepth(_this.heightAngle) + _this.calculateJump(_this.heightAngle);
            this.mesh.rotation.x = _this.heightAngle * -0.9 * _this.direction;

            this.calculateRotation(_this);

            // const t = Date.now() * 0.01;
            // if(_this.extraRotation > 0) _this.extraRotation -= 0.1;
            // this.mesh.rotation.y = (_this.direction *  _this.extraRotation) +  Math.sin(t) * 0.1;

        })
    }

    resetDNA()
    {
        this.extraRotation = 0;
        if(this.isJumpFish){
            this.animationTime = THREE.MathUtils.randInt(6000,10000);
        }else{
            this.animationTime = THREE.MathUtils.randInt(6000,18000);
        }
        this.isHit = false;
        // var animationTime = 16000 + (8000 * this.speed);

    }

    startMovingWithDelay(direction,extraDelay = 0){


        // rotate fish.
       // if(this.direction != direction){
            this.baseOrientationTarget = (direction  > 0) ? -Math.PI : 0 ;
       // }
       this.direction = direction;


        this.resetDNA();

        var delay =  extraDelay;// + Math.random() * 100;
       
        this.mesh.position.x = this.lane;
        this.mesh.position.y = this.calculateDepth(-1);
        

        const min = -2.0;
        const max = 2.6;
        const start = direction > 0 ?  max : min; 
        const end = direction > 0 ?  min : max; 

        this.mesh.position.z = start;
        this.lastPosition = start;

        var _this = this;
        if(this.moveTween) this.moveTween.stop();        
        if(this.diveTween) this.diveTween.stop();

        this.moveTween = new TWEEN.Tween(this.mesh.position)
                    .to(
                        { z: end }
                        ,
                        this.animationTime
                    )
                    .delay(delay)
                    .easing(TWEEN.Easing.Quadratic.InOut)
                    .start()
                    .onComplete(() => {
                        // move back.
                        _this.startMovingWithDelay(-direction);
                    });

        this.heightAngle = -1;

        const maxAngle = 1;// + (0.5-Math.random() * 0.4); 
        this.diveTween = new TWEEN.Tween(this)
        .to(
            { heightAngle: maxAngle }
            ,
            this.animationTime
            )
        .delay(delay)
        .easing(TWEEN.Easing.Quartic.InOut)
        .start()
        .onUpdate(() => {
            this.mesh.position.y = _this.calculateDepth(_this.heightAngle);
            if(this.isJumpFish) this.mesh.position.y +=  _this.calculateJump(_this.heightAngle);
            else  this.mesh.position.y -= _this.depthOffset;

            if(!_this.isJumpFish) this.mesh.position.y -= 0.1;

            this.calculateRotation(_this);
        })

    }

    calculateRotation(_this) {
        this.mesh.rotation.x = _this.heightAngle * -0.1 * _this.direction;
        const t = this.mesh.position.z * 13.4;
        if (_this.extraRotation > 0)
            _this.extraRotation -= 0.02;

           
        const sinRotation =  Math.sin(t) * 0.06;
        this.mesh.rotation.y = this.baseOrientation + (_this.direction * _this.extraRotation) + sinRotation;
        this.mesh.rotation.z = (_this.direction*0.1 * _this.extraRotation);

        const div = this.mesh.position.z - this.lastPosition;
        this.lastPosition = this.mesh.position.z;
        this.mixer.update(div * 10);

    }


    
}

export { Fish };
import axios from "axios";

axios.defaults.baseURL = `${window.location.origin}${process.env.VUE_APP_API_URL}`;
axios.defaults.params = {
  locale: localStorage.getItem("locale"),
};

const api = {
  getAvailableLocales: async () => {
    return await axios.get(`/locales`);
  },
  postForm: async (data) => {
    let postData = { data: { ...data } };
    postData[window.csrfTokenName] = window.csrfTokenValue;
    return await axios.post(`/form`, postData);
  },
  postGuess: async (data) => {
    let postData = { data: { ...data } };
    postData[window.csrfTokenName] = window.csrfTokenValue;
    return await axios.post(`/guess`, postData);
  },
};

export default api;

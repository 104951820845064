<template>
  <div class="screen screen__finished">
    <transition-group name="slide">
      <FirstSlide
        v-if="currentStep == 0"
        :user="user"
        key="0"
        @continue="handleFormSubmission"
        @replay="refreshPage"
      />
      <SecondSlide
        v-if="currentStep == 1"
        key="1"
        @continue="handleGuessSubmission"
        @replay="refreshPage"
      />
      <ThirdSlide v-if="currentStep == 2" key="2" @continue="currentStep++" />
      <FourthSlide v-if="currentStep == 3" key="3" @replay="refreshPage" />
    </transition-group>
  </div>
</template>

<script>
import api from "@/api";
import {
  FirstSlide,
  SecondSlide,
  ThirdSlide,
  FourthSlide,
} from "@/components/end-slides";

export default {
  name: "FinishedScreen",
  components: {
    FirstSlide,
    SecondSlide,
    ThirdSlide,
    FourthSlide,
  },
  data() {
    return {
      currentStep: 0,
      user: localStorage.getItem("user")
        ? JSON.parse(localStorage.getItem("user"))
        : null,
    };
  },
  methods: {
    async handleFormSubmission(data) {
      const answerRows = this.$store.state.questionAnswers.map((x) => {
        return {
          col1: x.question.title,
          col2: x.selected.answer,
          col3: x.correct,
        };
      });

      const payload = {
        language: localStorage.getItem("country"),
        answers: answerRows,
        correctAnswers: this.$store.state.questionAnswers.filter(
          (q) => q.correct
        ).length,
        koiswhacked: this.$store.state.koisWhacked,
        ...data,
      };

      try {
        await api
          .postForm(payload)
          .then((response) => {
            this.sendGtmEvent("button-click", {
              target: "form-button",
              value: this.$trans("formSubmitButton"),
              page: "form_page",
              language: localStorage.getItem("country"),
            });
            this.user = response.data;
            localStorage.setItem("user", JSON.stringify(this.user));
            this.currentStep++;
          })
          .catch((e) => {
            this.$toasted.show(e.response.data.error, {
              theme: "toasted-primary",
              position: "top-center",
              className: "error",
              duration: 5000,
            });
          });
      } catch (error) {
        console.log(error);
        this.$toasted.show(`Error: ${this.$trans("errorGeneral")}`, {
          theme: "toasted-primary",
          position: "top-center",
          className: "error",
          duration: 5000,
        });
      }
    },
    async handleGuessSubmission(data) {
      if (this.user) {
        let payload = {
          userId: this.user.id,
          ...data,
        };

        try {
          await api
            .postGuess(payload)
            .then((response) => {
              this.sendGtmEvent("button-click", {
                target: "guess-button",
                value: this.$trans("finalSubmit"),
                page: "guess_page",
                language: localStorage.getItem("country"),
              });
              this.user = response.data;
              this.currentStep++;
            })
            .catch((e) => {
              this.$toasted.show(e.response.data.error, {
                theme: "toasted-primary",
                position: "top-center",
                className: "error",
                duration: 5000,
              });
            });
        } catch (error) {
          console.log(error);
          this.$toasted.show(`Error: ${this.$trans("errorGeneral")}`, {
            theme: "toasted-primary",
            position: "top-center",
            className: "error",
            duration: 5000,
          });
        }
      } else {
        this.$toasted.show("Something went wrong", {
          theme: "toasted-primary",
          position: "top-center",
          className: "error",
          duration: 5000,
        });
      }
    },
    refreshPage() {
      if (this.user) {
        localStorage.setItem("user", JSON.stringify(this.user));
      }
      window.location.href = "/";
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/screens/finished-screen.scss";
</style>

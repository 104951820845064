import VueGtm from "@gtm-support/vue2-gtm";
import DeviceDetector from "device-detector-js";
import Vue from "vue";

// Setup GTM
Vue.prototype.$gtmIdPresent = false;
if (process.env.VUE_APP_GTM_ID) {
  Vue.use(VueGtm, {
    id: process.env.VUE_APP_GTM_ID,
    loadScript: true,
    enabled: true,
    debug: true,
    ignoredViews: ["Tour - Virtual tour"],
  });
  Vue.prototype.$gtmIdPresent = true;
}

// GTM track event mixin
Vue.mixin({
  methods: {
    sendGtmEvent(
      eventName,
      additionalData,
      currentTour = null,
      currentPano = null
    ) {
      if (
        this.$gtmIdPresent &&
        this.$gtm.enabled() &&
        process.env.NODE_ENV === "production"
      ) {
        const deviceDetector = new DeviceDetector();
        const detected = deviceDetector.parse(navigator.userAgent);

        // Fire GTM event
        this.$gtm.trackEvent({
          event: eventName,
          ...additionalData,
          device_type: `${detected.device.type}`,
          device_os: `${detected.os.name}${detected.os.version}`,
          device_browser: `${detected.client.name}${detected.client.version}`,
          tour_title: currentTour,
          tour_pano: currentPano,
        });
        if (
          window.google_tag_manager &&
          window.google_tag_manager[process.env.VUE_APP_GTM_ID]
        ) {
          window.google_tag_manager[
            process.env.VUE_APP_GTM_ID
          ].dataLayer.reset();
        }
      }
    },
    sendGtmView(viewName, viewPath) {
      if (
        this.$gtmIdPresent &&
        this.$gtm.enabled() &&
        process.env.NODE_ENV === "production"
      ) {
        this.$gtm.trackView(viewName, viewPath);
        if (
          window.google_tag_manager &&
          window.google_tag_manager[process.env.VUE_APP_GTM_ID]
        ) {
          window.google_tag_manager[
            process.env.VUE_APP_GTM_ID
          ].dataLayer.reset();
        }
      }
    },
  },
});

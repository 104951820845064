const parseYoutubeLink = (url) => {
  let regExp =
    /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  let match = url.match(regExp);
  return match && match[7].length == 11 ? match[7] : false;
};

const parseVimeoLink = (url) => {
  let regExp =
    /^.*(vimeo\.com\/)((channels\/[A-z]+\/)|(groups\/[A-z]+\/videos\/))?([0-9]+)/;
  let match = url.match(regExp);
  return match[5];
};

const parseEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

const isTouchDevice = () => {
  return "ontouchstart" in document.documentElement && window.innerWidth <= 900;
};

const mountBlurListeners = (onBlurCallback, onFocusCallback) => {
  let hidden, visibilityState, visibilityChange; // check the visiblility of the page

  if (typeof document.hidden !== "undefined") {
    hidden = "hidden";
    visibilityChange = "visibilitychange";
    visibilityState = "visibilityState";
  } else if (typeof document.mozHidden !== "undefined") {
    hidden = "mozHidden";
    visibilityChange = "mozvisibilitychange";
    visibilityState = "mozVisibilityState";
  } else if (typeof document.msHidden !== "undefined") {
    hidden = "msHidden";
    visibilityChange = "msvisibilitychange";
    visibilityState = "msVisibilityState";
  } else if (typeof document.webkitHidden !== "undefined") {
    hidden = "webkitHidden";
    visibilityChange = "webkitvisibilitychange";
    visibilityState = "webkitVisibilityState";
  }

  if (
    typeof document.addEventListener === "undefined" ||
    typeof hidden === "undefined"
  ) {
    // not supported
  } else {
    document.addEventListener(
      visibilityChange,
      function () {
        switch (document[visibilityState]) {
          case "visible":
            if (onFocusCallback) {
              onFocusCallback();
            }
            break;
          case "hidden":
            if (onBlurCallback) {
              onBlurCallback();
            }
            break;
        }
      },
      false
    );
  }
};

export {
  parseYoutubeLink,
  parseVimeoLink,
  parseEmail,
  isTouchDevice,
  mountBlurListeners,
};

<template>
  <div class="screen screen__rotate" v-if="isLandscape">
    <div class="img__wrapper">
      <img src="assets/img/rotate-screen.svg" alt="Rotate device image" />
    </div>
    <p>{{ $trans("gameRotateDeviceTitle") }}</p>
  </div>
  <div class="screen screen__rotate" v-else-if="isWrongResolution">
    <p class="resolution">{{ $trans("gameMinResolutionTitle") }}</p>
  </div>
</template>

<script>
export default {
  name: "DimensionsScreen",
  props: ["isLandscape", "isWrongResolution"],
};
</script>

<style lang="scss" scoped>
@import "@/scss/screens/dimensions-screen.scss";
</style>

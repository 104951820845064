import * as THREE from "three";
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import * as SoundController from "@/helpers/sounds";
import { ofMap } from "./Utils";


class AnimationParameters {
    constructor() {
        this.cameraOffset = new THREE.Vector3(0, 0.0, 0.0);
        this.catOffset = new THREE.Vector3(0, 0.0, 0.0);
        this.isOnlyFP = false;
    }
}


class MainAnimation {

    constructor() {
        this.mesh = null;
        this.positionAction = null;
        this.timeAction = null;
        this.catAction = null;
        this.trackSize = 0;
        this.state = "START";

        this.currentParams = null;
        
        // START
        this.paramStart = new AnimationParameters();
        this.paramStart.cameraOffset = new THREE.Vector3(0, 0.0, 0);
        this.paramStart.catOffset = new THREE.Vector3(0, 0.05, 0);

        // TRACK
        this.paramTrack = new AnimationParameters();
        this.paramTrack.cameraOffset = new THREE.Vector3(0, 0.32, 0.0);
        this.paramTrack.catOffset = new THREE.Vector3(0, -.35, 0);

        // FENCE
        this.paramFence = new AnimationParameters();
        this.paramFence.cameraOffset = new THREE.Vector3(0, 0.0, 0.0);
        this.paramFence.catOffset = new THREE.Vector3(0, 0, 0);

        // FENCE JUMP
        this.paramFenceJump = new AnimationParameters();

        // FPV
        this.paramFPV = new AnimationParameters();
        this.paramFPV.isOnlyFP = true;
        this.paramFPV.catOffset = new THREE.Vector3(0, -0.50, 0);

        // EATING
        this.paramEating = new AnimationParameters();
        this.paramEating.cameraOffset = new THREE.Vector3(0, 0, 0);
        this.paramEating.catOffset = new THREE.Vector3(0, 0, 0);

        // POOL
        this.paramPool = new AnimationParameters();
        this.paramPool.isOnlyFP = true;
        this.paramPool.catOffset = new THREE.Vector3(0, -0.80, 0);


        this.currentParams = this.paramTrack;
        this.onStateChange = null;

        this.soundPlayedHistory = [];

    }


    load(callback) {

        const loader = new GLTFLoader();
        const _this = this;

        loader.load('models/Animations.glb', function (gltfData) {

            //console.log("==== Camera Animation glb ====");

            const mesh = gltfData.scene;
            _this.mesh = mesh;
            _this.animations = gltfData.animations;

            // animations
            const mixer = new THREE.AnimationMixer(mesh);

            const nrOfAnimations = _this.animations.length;
            for (var i = 0; i < nrOfAnimations; i++) {
                const animName = _this.animations[i].name;
                if (animName.includes("camera_empty")) {
                    _this.positionAction = mixer.clipAction(_this.animations[i]);
                    //console.log("found: " + animName);

                }

                if (animName.includes("eyetarget")) {
                    _this.timeAction = mixer.clipAction(_this.animations[i]);
                    //console.log("found: " + animName);

                }

                if (animName.includes("cat_empty")) {
                    _this.catAction = mixer.clipAction(_this.animations[i]);
                    //console.log("found: " + animName);

                }

            }

            _this.trackSize = _this.positionAction._clip.tracks[0].values.length / 3;
            callback(mesh);

        }, undefined, function (e) {

            console.error(e);

        });
    }


    getTimeAfterPoolGame() {
        return 1180;
    }



    setStateByTime(pathTime) {

        this.setSoundStateByTime(pathTime);

        let newState = null;

        if (this.state == "QUIZ") return;

        if (pathTime < 18) {
            newState = "START";
            this.currentParams = this.paramStart;
        }
        // FPV
        else if (pathTime > 4 && pathTime < 100  
            || pathTime > 1258 && pathTime < 1281   // basement
            || pathTime > 1462 && pathTime < 1529   // basement
            || pathTime > 1720 && pathTime < 1780   // garden to house
            || pathTime > 920 && pathTime < 1000    // gardenhouse to garden 
            || pathTime > 1150 && pathTime < 1170   // exit pool
        ) { 
            newState = "FPV";
            this.currentParams = this.paramFPV;
        }
        else if (pathTime > 0.5 && pathTime < 10) {
            newState = "STARTWALK";
            this.currentParams = this.paramTrack;
        }
        else if (pathTime > 1080 && pathTime < 1150) {
            newState = "POOL";
            this.currentParams = this.paramPool;
        }
        else if (pathTime > 617 && pathTime < 666) {
            newState = "FENCE_JUMP";
            this.currentParams = this.paramFenceJump;
        }
        else if (pathTime > 666 && pathTime < 940) {
            newState = "FENCE";
            this.currentParams = this.paramFence;
        }
        else if (pathTime > 2045) {
            newState = "EATING";
            this.currentParams = this.paramEating;
        }
        else{
            newState = "TRACK";
            this.currentParams = this.paramTrack;
        }


        if (newState != this.state) {
            this.setState(newState);
        }

    }

    setState(newState) {
        this.state = newState;
        if (this.onStateChange)
            this.onStateChange(newState);
    }



    // LIGHT adjustments
    getLightItensity(pathTime){
        if(pathTime > 1267 && pathTime < 1518) return 0.08;
        else return 1;

    }

    // SOUND adjustments
    checkForSoundPlayed(pathTime,position,key){
        if (pathTime > position && pathTime > position + 40) {
            let sound = this.soundPlayedHistory.find((x) => x === key);
            if(!sound){
                this.soundPlayedHistory.push(key);
                SoundController.play(key);
                console.log("start playing " + key);

                SoundController.fadeIn(key, 1000);
            }
        }
    }

    mapSound(pathTime,mapData,key){

        let lowerSegmentMatch = null;
        let upperSegmentMatch = null;
        let matchingIndex = -1;

        const lastPathTime = mapData[mapData.length-1].path
        // check for stop with a 20 frames buffer.
        if(pathTime > lastPathTime && pathTime < lastPathTime + 20 ){
            SoundController.stop(key);
            if (window.enableLogging) {
                console.log(`Stop pathtime map for: ${key}`);
            }
            return;
        }

        for(var i = 0; i < mapData.length-1; ++i){

            const lowerData = mapData[i];
            const upperData = mapData[i+1];

            if(lowerData.path < pathTime && pathTime < upperData.path){
                //matchingSegment = lowerData;
                lowerSegmentMatch = lowerData;
                upperSegmentMatch = upperData;
                matchingIndex = i;
                continue;
            }
        }

       
        if(matchingIndex >= 0){
            // first segment start
            if(matchingIndex === 0){
                let sound = this.soundPlayedHistory.find((x) => x === key);
                if(!sound){
                    console.log("START playing " + key);
                    this.soundPlayedHistory.push(key);
                    SoundController.setLoop(key);
                    SoundController.play(key);
                    if (window.enableLogging) {
                        console.log(`Start pathtime map for: ${key}`);
                    }

                }
            }

            const newVolume = ofMap(pathTime,lowerSegmentMatch.path,upperSegmentMatch.path,lowerSegmentMatch.volume,upperSegmentMatch.volume,true) *0.01;
            SoundController.increaseTo(key,{volume : newVolume});
            if (window.enableLogging) {
                console.log(`Volume update: ${key} to ${Math.round(newVolume * 100)}%`);
            }
        }
    }

    setSoundStateByTime(pathTime) {
        
        this.checkForSoundPlayed(pathTime,1563,"cars")
        this.checkForSoundPlayed(pathTime,1820,"walkUpstairs")

        
        const basementMap = [
            { path : 1266, volume : 0},            
            { path : 1277, volume : 95},
            { path : 1400, volume : 100},
            { path : 1458, volume : 95},        
            { path : 1497, volume : 0},                
        ];

        this.mapSound(pathTime,basementMap,"basementAmbient");




        const rockyMountainMap = [
            { path : 634, volume : 1},            
            { path : 936, volume : 35},
            { path : 1045, volume : 100},
            { path : 1094, volume : 100},
            { path : 1258, volume : 0},        
        ];

        this.mapSound(pathTime,rockyMountainMap,"rockyMountain");




        const birdswallowMap = [
           { path : 0, volume : 100},            
           { path : 1258, volume : 80},            
            { path : 1272, volume : 20},
            { path : 1277, volume : 10},
            { path : 1458, volume : 20},        
            { path : 1497, volume : 20},        
            { path : 1724, volume : 20},        
            { path : 1728, volume : 17},        
            { path : 1781, volume : 15},        
            { path : 1820, volume : 8},        
            { path : 1961, volume : 12},        
        ];

        this.mapSound(pathTime,birdswallowMap,"birdswallow");

        // const morningbirdsMap = [
        //     { path : 0, volume : 100},            
        //     { path : 1258, volume : 100},            
        //     { path : 1272, volume : 10},
        //     { path : 1277, volume : 5},
        //     { path : 1458, volume : 10},        
        //     { path : 1497, volume : 90},        
        //     { path : 1724, volume : 90},        
        //     { path : 1728, volume : 70},        
        //     { path : 1781, volume : 60},        
        //     { path : 1820, volume : 30},        
        // ];

        // this.mapSound(pathTime,morningbirdsMap,"morningbirds");


        const cityAtmosphereMap = [
            { path : 1277, volume : 0},        
            { path : 1458, volume : 10},        
            { path : 1497, volume : 90},        
            { path : 1724, volume : 90},        
            { path : 1728, volume : 70},        
            { path : 1781, volume : 40},        
            { path : 1820, volume : 30},        
        ];

        this.mapSound(pathTime,cityAtmosphereMap,"cityAtmosphere");


        const kojiIslandMap = [
            { path : 10, volume : 30}, 
            { path : 1045, volume : 30},        
            { path : 1090, volume : 10},        
            { path : 1094, volume : 10},        
            { path : 1258, volume : 30},        
            { path : 1266, volume : 30},        
            { path : 1272, volume : 0},        
            { path : 1277, volume : 0},        
            { path : 1458, volume : 0},        
            { path : 1497, volume : 12},        
            { path : 1724, volume : 12},                   
            { path : 1961, volume : 3},        
        ];

       this.mapSound(pathTime,kojiIslandMap,"kojiIsland");

    }


    getInterpolatedValueAtTime(time, values) {
        if (time < 0) time += this.trackSize;

        let timeFloor = Math.floor(time);
        let timeCeil = Math.ceil(time);

        const v0 = this.getVectorAtTime(timeFloor, values);
        const v1 = this.getVectorAtTime(timeCeil, values);

        let mod = time % 1;
        return v0.lerp(v1, mod);
    }


    getCatPositionAtTime(time) {

        const values = this.catAction._clip.tracks[0].values;
        return this.getInterpolatedValueAtTime(time, values);
    }

    getPositionAtTime(time) {

        const values = this.positionAction._clip.tracks[0].values;
        return this.getInterpolatedValueAtTime(time, values);
    }

    getEyeAtTime(time) {

        const values = this.timeAction._clip.tracks[0].values;
        return this.getInterpolatedValueAtTime(time, values);
    }

    getVectorAtTime(time, values) {

        const offset = time * 3;
        const x = values[offset];
        const y = values[offset + 1];
        const z = values[offset + 2];

        return new THREE.Vector3(x, y, z);
    }



}

export { MainAnimation };
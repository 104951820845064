<template>
  <div
    :class="`prompt prompt__intro ${currentPrompt >= 3 ? 'hide-bg' : ''}`"
    @scroll="handleScroll"
  >
    <!-- <transition-group name="slide"> -->
    <p
      class="prompt__text"
      v-if="currentPrompt === 1"
      key="1"
      :style="`--duration: ${times.promptDuration}ms`"
    >
      {{ $trans("gameFirstTextPrompt") }}
    </p>
    <p
      class="prompt__text"
      v-if="currentPrompt === 2"
      key="2"
      :style="`--duration: ${times.promptDuration}ms`"
    >
      {{ $trans("gameSecondTextPrompt") }}
    </p>
    <p
      class="prompt__text"
      v-if="currentPrompt === 3"
      key="3"
      :style="`--duration: ${times.promptDuration}ms`"
    >
      {{ $trans("gameThirdTextPrompt") }}
    </p>
    <!-- After scroll -->
    <p
      class="prompt__text"
      v-if="currentPrompt === 5"
      key="5"
      :style="`--duration: ${times.promptDuration}ms`"
    >
      {{ $trans("gameFourthTextPrompt") }}
    </p>
    <!-- </transition-group> -->

    <transition name="fade">
      <div
        class="scroll ui__element ui__element--bottom-center"
        v-if="scrollPromptVisible"
      >
        <div class="mouse" v-if="!isTouchDevice()">
          <lottie
            class="mouse__animation"
            :options="mouseOptions"
            v-on:animCreated="handleAnimation"
          />
        </div>
        <div class="touch" v-else>
          <lottie
            class="touch__animation"
            :options="touchOptions"
            v-on:animCreated="handleAnimation"
          />
        </div>
        <p>{{ $trans("gameScrollPrompt") }}</p>
      </div>
    </transition>
    <div class="bg-overlay"></div>
  </div>
</template>

<script>
import Lottie from "vue-lottie";
import * as SoundController from "@/helpers/sounds";
import * as mouseAnimationData from "../../../web/assets/lottie/scroll.json";
import * as touchAnimationData from "../../../web/assets/lottie/touch.json";
import { isTouchDevice } from "@/helpers";

export default {
  name: "IntroPrompt",
  components: { lottie: Lottie },
  data() {
    return {
      currentPrompt: 0,
      scrollPromptVisible: false,
      mouseOptions: { animationData: mouseAnimationData.default },
      touchOptions: { animationData: touchAnimationData.default },
      animationSpeed: 2,
      promptInterval: null,
      promptCueTimeout: null,
      times: {
        waitBeforeCue: 1000, // 1s
        promptDuration: 3000, // 3s
        waitBetweenCue: 5000, // 5s
      },
    };
  },
  mounted() {
    setTimeout(() => {
      this.cuePrompts();
    }, this.times.waitBeforeCue);
  },
  methods: {
    isTouchDevice: isTouchDevice,
    cuePrompts() {
      // Miauw 1
      this.currentPrompt = 1;
      SoundController.playRandomMeow();

      // Display scrollcue
      if (!this.scrollPromptVisible) {
        this.setupScrollInteractions();
      }

      this.promptInterval = setInterval(() => {
        this.currentPrompt++;

        // Miauw [2-3]
        if (this.currentPrompt < 4) {
          SoundController.playRandomMeow();
        }

        if (this.currentPrompt === 4) {
          clearInterval(this.promptInterval);
          this.promptCueTimeout = setTimeout(() => {
            this.cuePrompts();
          }, this.times.waitBetweenCue);
        }
      }, this.times.promptDuration);
    },
    setupScrollInteractions() {
      this.$emit("allow-interaction");
      this.scrollPromptVisible = true;
      window.addEventListener("wheel", this.handleScroll);
      window.addEventListener("touchstart", this.handleScroll);
    },
    handleAnimation(anim) {
      this.anim = anim;
      this.anim.setSpeed(this.animationSpeed);
    },
    handleScroll() {
      clearInterval(this.promptInterval);
      clearTimeout(this.promptCueTimeout);
      this.currentPrompt = 5;
      // SoundController.playRandomMeow(0.5);
      setTimeout(() => {
        this.currentPrompt = 6;
        this.scrollPromptVisible = false;
      }, this.times.promptDuration);
      // setTimeout(() => {
      //   this.$store.state.currentState = this.$uiState.PLAYING;
      //   this.$store.state.currentGameState = this.$gameState.WALKING;
      // }, 10000);
      window.removeEventListener("wheel", this.handleScroll);
      window.removeEventListener("touchstart", this.handleScroll);
    },
  },
};
</script>

<style lang="scss" scoped>
.prompt__intro {
  pointer-events: none;
  // transition: all 0.2 linear;

  * {
    color: $color-text--inverse;
    // pointer-events: all;
  }

  // .bg-overlay {
  //   background: linear-gradient(
  //     180deg,
  //     rgba(darken($color-green--primary, 30%), 0.5) 22.7%,
  //     rgba(darken($color-green--primary, 30%), 0.3) 100%
  //   );
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   bottom: 0;
  //   right: 0;
  //   opacity: 1;
  //   transition: all $basic-transition-1000;
  //   z-index: -1;
  // }

  // &.hide-bg .bg-overlay {
  //   opacity: 0;
  // }

  p.prompt__text {
    width: 90%;
    max-width: 90%;
    position: absolute;
    font-weight: bold;
    @include fontSize(4.8rem, 6.2, 6.2rem, 1);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    // transition: all $basic-transition-1000;
    animation: slide var(--duration) cubic-bezier(0.86, 0, 0.07, 1) forwards;

    @keyframes slide {
      0% {
        transform: translate(-50%, 0%);
        opacity: 0;
      }

      20% {
        transform: translate(-50%, -50%);
        opacity: 1;
      }

      70% {
        transform: translate(-50%, -50%);
        opacity: 1;
      }

      100% {
        opacity: 0;
        transform: translate(-50%, -500%);
      }
    }

    // &.slide-enter-active,
    // &.slide-leave-active {
    //   transition: all $basic-transition-1000;
    // }

    // &.slide-enter,
    // &.slide-leave-to {
    //   opacity: 0;
    // }

    // &.slide-enter {
    //   transform: translate(-50%, 0%);
    // }

    // &.slide-leave-to {
    //   transform: translate(-50%, -500%);
    // }
  }

  .scroll {
    bottom: 2.4rem;
    width: 20rem;
    @include scaleWidth(20);

    display: flex;
    align-items: center;
    flex-direction: column;
    grid-gap: 1.6rem;

    .mouse {
      width: 6rem;
      @include scaleWidth(4);

      &__animation {
        // #fff
        filter: invert(100%) sepia(0%) saturate(6922%) hue-rotate(23deg)
          brightness(111%) contrast(105%);
      }
    }

    .touch {
      width: 10rem;
      @include scaleWidth(10);
    }

    @include for-tablet-landscape-up {
      bottom: 10rem;
      grid-gap: 3rem;
      @include scaleWidth(15);

      .mouse,
      .touch {
        @include scaleWidth(6);
      }
    }

    p {
      font-weight: bold;
      @include fontSize(1.6rem, 2.4, 2.4rem, 1.2);
    }

    &.fade-enter-active,
    &.fade-leave-active {
      transition: all $basic-transition-500 0.5s;
      transform: translate(-50%, 0);
    }

    &.fade-enter,
    &.fade-leave-to {
      opacity: 0;
    }

    &.fade-enter,
    &.fade-leave-to {
      transform: translate(-50%, 50%);
    }
  }

  @include for-giant-desktop-up {
    p.prompt__text {
      font-size: rs(62) !important;
    }

    .scroll {
      bottom: rs(100);
      width: rs(150);
      grid-gap: rs(30);

      .mouse {
        width: rs(60);
      }

      p {
        font-size: rs(24) !important;
      }
    }
  }
}
</style>

import * as THREE from "three";
//import Stats from "three/examples/jsm/libs/stats.module.js";

//import { GUI } from "three/examples/jsm/libs/lil-gui.module.min.js";
//import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import { TWEEN } from "three/examples/jsm/libs/tween.module.min.js";


import * as SoundController from "@/helpers/sounds";


import { ofMap,TriggerEvent } from "./Utils.js";
import { House } from "./House.js";
import { Cat } from "./Cat.js";
import { MainAnimation } from "./MainAnimation.js";
import { ActionPanels } from "./ActionPanels.js";
import { PoolGame } from "./PoolGame.js";

import normalizeWheel from "./normalizeWheel.js";

const NEAR = 0.1,
  FAR = 1000;

//let stats = null;
let scene = null;

let camera = null;
let renderer = null;
let clock = new THREE.Clock(false);
//let gui = null;
let mouseOffset = new THREE.Vector2(0,0);
let mouseOffsetTarget = new THREE.Vector2(0,0);
let lastTouchY = -1;
let isTouching = false;
let cameraSpeed = 0;
//let oribitControls;
let catVisibleFactor = 0;

let ambientLight = new THREE.AmbientLight(0xFFFFFF, 0.2);
let directionalLight2;
let directionalLight;

let house = null;
let poolGame = null;
let mainAnimation = null;
let actionPanels = null;
let cat = null;
let pathTimeLerp = 0;

let cameraOffset = new THREE.Vector3();
let offsetFactor = 0;
let offsetFactorTween = null;

let cameraPosition = new THREE.Vector3();
let eyePosition = new THREE.Vector3();

let cameraPositionTarget = new THREE.Vector3();
let eyePositionTarget = new THREE.Vector3();

// set timeout ref
let zoomOutTimer = null;
// tween ref
let zoomOutTween = null;

let isAutoPilot = false;

// SETTINGS
const settings = {
  freeCam: false,
  maxWalkSpeed: 0.034,
  walkDemping: 0.045,
  cameraFOV: 150,
  cameraSpeed: 0,
  swipeScrollForce: 0.001,
  pathTime: 0,
  catScale: 0.06,
  cameraLerp: 0.08,
  catLerp: 0.008,
  offsetFactorLerp: 1,
  lookAngleVertical : 0.8,
  lookAngleHorizontal : 0.9,
  state : "none",
  antialias : true,
  autoRender : true,
  hasMouseLook : false,
  interactionAllowed: false,
  isQuizActive: false,
  noUITriggers: false,
};

const tweens = {
  zoomOutFactor: 1,
};

let isZoomOut = false;
let isZoomIn = true;

export function setup(canvasElement) {
  // eslint fixes
  console.log(offsetFactorTween);
  console.log(isZoomIn);
 
  setupLoaderEvents();
 // setupGui();

  settings.hasMouseLook = !('ontouchstart' in document.documentElement);

  // setup render
  renderer = new THREE.WebGLRenderer({
    canvas: canvasElement,
    antialias: settings.antialias ,
    alpha: false,
  });

  renderer.toneMapping = THREE.ACESFilmicToneMapping;
  renderer.toneMappingExposure = 1.2;
  renderer.outputEncoding = THREE.sRGBEncoding;

  renderer.setSize(window.innerWidth, window.innerHeight);
  renderer.setPixelRatio(1);
  renderer.setPixelRatio(window.devicePixelRatio);
  

  // setup scene
  scene = new THREE.Scene();
  scene.background = new THREE.Color(0x54bddd);

  setupCamera();
  setupTouch();

  // setup house
  house = new House();
  house.load((model) => {
    scene.add(model);
  
    // setup pool
    poolGame = new PoolGame(house.poolSurfacePosition,scene);
    poolGame.load(() => {
      scene.add(poolGame.group);
    });

  });


  

//  setupHelpers();

  // setup mainAnimation
  mainAnimation = new MainAnimation();
  mainAnimation.load(() => {
    //setup cat
    cat = new Cat();
    cat.load(() => {
      const scale = settings.catScale;
      cat.mesh.scale.set(scale, scale, scale);
      scene.add(cat.mesh);

      cat.mesh.position.copy(mainAnimation.getPositionAtTime(0));
      cat.mesh.position.y -= 0.24;
      cat.mesh.position.z += 0.4;
    });

    cameraPositionTarget = mainAnimation.getPositionAtTime(0);
    eyePositionTarget = mainAnimation.getEyeAtTime(0);

    cameraPosition = cameraPositionTarget.clone();
    eyePosition = eyePositionTarget.clone();

    setupActionPanels();
  });

  mainAnimation.onStateChange = stateChange;

  setupLighting();
  setupKeyboard();
  
  const fog = new THREE.Fog(0x3f7b9d, 8, 30);
  scene.fog = fog;

  // set inital sounds on start
  mainAnimation.setSoundStateByTime(1);
  
}




function setDebug2(){
  poolGame.stopGame();
}


function setDebug(){
  settings.pathTime = 1846;
  //poolGame.startGame();
  interactionAllowed();
  settings.noUITriggers = true;
}

function tweenOffsetFactorTo(value, time) {
  offsetFactorTween = new TWEEN.Tween(settings)
    .to({ offsetFactorLerp: value }, time)
    .easing(TWEEN.Easing.Circular.InOut)
    .start();
  if (mainAnimation.state == "TRACK") cat.setRunning();
}

function stateChange(newState) {
  if (newState == "TRACK") {
    tweenOffsetFactorTo(1, 3000);
    cat.setRunning();

    cat.show();
  } else if (newState == "FPV") {
    startZoomIn();
    cat.hide();
  } else if (newState == "JUMP") {
    tweenOffsetFactorTo(0, 3000);
    cat.hide();
  } else if (newState == "STARTWALK") {
    cat.setRunning();
    cat.hide();
  } else if (newState == "START") {
    cat.setIdleSit();
    if (cat.mesh) cat.mesh.visible = true;
  } else if (newState == "FENCE") {
    cat.show();
    cat.setRunning();

    poolGame.startAnimation();
    tweenOffsetFactorTo(0, 300);
  } else if (newState == "FENCE_JUMP") {
    cat.hide();
    tweenOffsetFactorTo(0, 3000);
  } else if (newState == "EATING") {
    cat.setEating();
    const time = Math.round(clock.getElapsedTime() / 60);
    TriggerEvent("reached-end",{ detail: { number: time }});
    SoundController.play("catEating");
    SoundController.fadeIn("catEating", 1000);


    if (zoomOutTimer) clearTimeout(zoomOutTimer);
  }else if(newState == "POOL"){
    cat.hide();
    if(!settings.noUITriggers) TriggerEvent("intro-whackakoi",{ detail: { number: 0 }});
    settings.interactionAllowed = false;
    startZoomOut(true);
    cameraSpeed = 0;
    // TWEEn to end
    let t = new TWEEN.Tween(settings)
    .to(
        { pathTime: 1147 }
        ,
        300
    )
    t.start()
  
  }
}



// function setupGui() {
//   // GUI
//   gui = new GUI({ width: 280 });
//   gui.add(settings, "freeCam").onChange((value) => {
//     oribitControls.enabled = value;
//   });

//   gui.add(settings, "state").listen(); 
//   gui.add(tweens, "zoomOutFactor").listen(); 
//   gui.add(settings, "hasMouseLook").listen();
//   gui.add(settings, "interactionAllowed").listen();

//   gui.add(settings, "maxWalkSpeed", 0, 0.1).step(0.001);
//   gui.add(settings, "walkDemping", 0, 0.1).step(0.001);
//   gui.add(settings, "swipeScrollForce", 0.0001, 0.008).step(0.0001);
//   gui.add(settings, "pathTime", 0.0, 2300).step(1).listen();

//   gui.add(settings, "cameraLerp", 0.0, 0.2).step(0.001);
//   gui.add(settings, "catLerp", 0.0, 0.2).step(0.001);
//   gui.add(settings, "lookAngleHorizontal", 0.0, 2.8).step(0.01);
//   gui.add(settings, "lookAngleVertical", 0.0, 2.8).step(0.01);

//   gui.add(settings, "noUITriggers");

//   gui.close();
// }

function setupKeyboard() {
  document.addEventListener("keydown", onDocumentKeyDown, false);
}


function onDocumentKeyDown(event) {
  var keyCode = event.which;
  
  if (keyCode == 38) {
    walk(20);
  } else if (keyCode == 40) {
    walk(-20);
  }
  else if (keyCode == 68) {
    setDebug();
   }
   else if (keyCode == 69) {
    setDebug2();
   }
    // r key toggle autorender
  else if (keyCode == 82) {
    settings.autoRender = !settings.autoRender;
    if(settings.autoRender) animate();
  }
}

// export function setupHelpers() {
//   // STATS
//   //stats = new Stats();
//   //statsContainer.appendChild(stats.dom);

//   // Orbit controls
//   oribitControls = new OrbitControls(camera, renderer.domElement);
//   oribitControls.enableDamping = true;
//   oribitControls.target.set(0, 0.5, 0);
//   oribitControls.enabled = settings.freeCam;
// }

function setupLoaderEvents() {
  
  THREE.DefaultLoadingManager.onProgress = function (
    url,
    itemsLoaded,
    itemsTotal
  ) {
    const pct = Math.round((itemsLoaded / itemsTotal) * 100);
    TriggerEvent("loading-world",{ detail: { progress: pct }});
  };

  THREE.DefaultLoadingManager.onError = function (url) {
    console.log("There was an error loading " + url);
  };
}

function setupActionPanels() {
  actionPanels = new ActionPanels( mainAnimation);
  actionPanels.load(() => {
    scene.add(actionPanels.mesh);
  });

  actionPanels.openQuizCallback = () => {
    settings.isQuizActive = true;
    cameraSpeed = 0;
  };

  actionPanels.setupParticles(scene);
}

function setupCamera() {
  camera = new THREE.PerspectiveCamera(
    60,
    window.innerWidth / window.innerHeight,
    NEAR,
    FAR
  );
  camera.position.set(0, 10, -30);
}

function walk(delta) {

  // always move forward
  delta = Math.abs(delta);

  const state = mainAnimation.state;
  const isInteractive = !isAutoPilot 
  && (state == "TRACK" || state == "FPV" || state == "START" || state == "FENCE")
  && settings.interactionAllowed
  && !settings.isQuizActive;


  if (isInteractive) {
    delta = Math.min(50, delta);
    cameraSpeed += delta * 0.2 * settings.swipeScrollForce;
    clearTimeout(zoomOutTimer);
  }

  if (mainAnimation.state == "TRACK") {
    zoomOutTimer = setTimeout(() => {
      startZoomOut(false);
    }, 1200);

    if (tweens.zoomOutFactor > 0) {
      startZoomIn();
    }
  }

  
}

// events coming from the UI

export function setupScene(){
  console.log("setupSCene");
}

export function finishQuiz(){
  var newTime = actionPanels.getRestartTimeAfterQuiz();
  settings.pathTime = Math.max(newTime,settings.pathTime);
  settings.isQuizActive = false;
}

export function startWhackAKoi(){
  console.log("startWhackAKoi");
  poolGame.startGame();
}

export function endWhackAKoi(){
  poolGame.stopGame();
  settings.interactionAllowed = true;
}

export function skipWhackAKoi(){
  finishWhackAKoi();
}

export function interactionAllowed(){
  settings.interactionAllowed = true;
  clock.start();
}

export function finishWhackAKoi(){
 var newTime = mainAnimation.getTimeAfterPoolGame();
 settings.pathTime = newTime;
 settings.interactionAllowed = true;

}





function setupTouch() {

  var el = document.getElementById("canvas");

  // TOUCH
  el.addEventListener("touchstart", handleStart, false);
  el.addEventListener("touchend", handleEnd, false);
  el.addEventListener("touchcancel", handleEnd, false);
  el.addEventListener("touchmove", handleMove, false);

  // MOUSE
  el.addEventListener(
    "wheel",
    function (e) {
      if (!settings.freeCam) {
        const deltaY = normalizeWheel(e).pixelY;
        walk(deltaY);
      }
    },
    false
  );
  // LOOK AROUND
  document.addEventListener(
    "mousemove",
    function (event) {

      const isQuiz = settings.isQuizActive;
      const hAngle = isQuiz ? 0.03 : settings.lookAngleHorizontal;
      const vAngle = isQuiz ? 0.03 : settings.lookAngleVertical;

      var width = window.innerWidth;
      mouseOffsetTarget.x = ((width * 0.5) - event.clientX) / width;
      mouseOffsetTarget.x *= hAngle;

      var height = window.innerWidth;
      mouseOffsetTarget.y = ((height * 0.5) - event.clientY) / height;
      mouseOffsetTarget.y *= vAngle;
    },
    false
  );


  // TAP
  document.addEventListener(
    "mousedown",
    function (evt) {
      if(poolGame && evt.target === document.getElementById('canvas')) poolGame.tap();
    });

}

function handleStart(evt) {
  evt.preventDefault();
  var touches = evt.changedTouches;
  isTouching = true;
  if(poolGame && evt.target === document.getElementById('canvas')) poolGame.tap();
  lastTouchY = touches[0].pageY;
}

function handleMove(evt) {
  evt.preventDefault();
  var touches = evt.changedTouches;
  if (isTouching && touches.length > 0) {
    let touchDiv = touches[0].pageY - lastTouchY;
    lastTouchY = touches[0].pageY;
    walk(touchDiv * 2.5);
  }
}

function handleEnd(evt) {
  isTouching = false;
  evt.preventDefault();
}



function startZoomOut(forced, animationTime = 3000) {
  if (!forced && (isZoomOut || mainAnimation.state == "FPV")) return;

  isZoomOut = true;
  zoomOutTween = new TWEEN.Tween(tweens)
    .to({ zoomOutFactor: 1.0 }, animationTime)
    .easing(TWEEN.Easing.Quartic.Out)
    .start()
    .onComplete(() => {
      isZoomIn = false;
    });
}

function startZoomIn() {
  if (zoomOutTween) zoomOutTween.stop();
  //  if(!isZoomIn){
  isZoomIn = true;
  isZoomOut = true;

  zoomOutTween = new TWEEN.Tween(tweens)
    .to({ zoomOutFactor: 0.0 }, 800)
    .easing(TWEEN.Easing.Quartic.Out)
    .start()
    .onComplete(() => {
      isZoomOut = false;
    });
  // }
}

function animate() {
  if(settings.autoRender) requestAnimationFrame(animate);

  TWEEN.update();
  //stats.update();

  settings.state = mainAnimation.state;

  if (mainAnimation.mesh) {
    if (mainAnimation.state == "FPV") {
      tweens.zoomOutFactor = 0.0;
    }

    // auto pilot
    checkAutoPilot();

    // POOL position is a tween.
    if(mainAnimation.state != "POOL"){
      settings.pathTime += cameraSpeed * 30;
      settings.pathTime = Math.max(0, settings.pathTime);
      // REACHED the end.
      if (settings.pathTime > mainAnimation.trackSize - 1){
        settings.pathTime = mainAnimation.trackSize - 1;
      }
        pathTimeLerp += (settings.pathTime - pathTimeLerp) * 0.35;
    }else{
        pathTimeLerp = settings.pathTime;
    }


    mainAnimation.setStateByTime(pathTimeLerp);
    adjustLightIntesity(mainAnimation.getLightItensity(pathTimeLerp));

    cameraPositionTarget = mainAnimation
      .getPositionAtTime(pathTimeLerp)
      .clone();
    const cameraPositionNormal = mainAnimation
      .getPositionAtTime(pathTimeLerp)
      .clone();

    let catTimePos = pathTimeLerp-4;
    if (mainAnimation.state == "FPV") catTimePos -= 14;
    if (pathTimeLerp < 10) catTimePos = 0;

    const cameraPositionCat = mainAnimation
      .getPositionAtTime(catTimePos)
      .clone();
    const cameraPositionFar = mainAnimation
      .getPositionAtTime(Math.max(pathTimeLerp - 5, 0))
      .clone();
    eyePositionTarget = mainAnimation.getEyeAtTime(pathTimeLerp).clone();

    const lerpAnimationPath = settings.pathTime < 10 ? 1 : settings.cameraLerp;

    cameraPosition.lerp(cameraPositionTarget, lerpAnimationPath);
    eyePosition.lerp(eyePositionTarget, lerpAnimationPath);


    offsetFactor = tweens.zoomOutFactor;

    // calculate the view angle vector
    cameraOffset = cameraPosition.clone();
    cameraOffset.sub(eyePosition).normalize();

    cameraOffset.multiplyScalar(0.3);

    const parms = mainAnimation.currentParams;
    cameraOffset.x += parms.cameraOffset.x;
    cameraOffset.y += parms.cameraOffset.y;
    cameraOffset.z += parms.cameraOffset.z;

    var catPosition = cameraPositionCat.clone();

    if (
      mainAnimation.state == "START" ||
      mainAnimation.state == "FENCE" ||
      mainAnimation.state == "EATING"
    ) {
      catPosition = mainAnimation.getCatPositionAtTime(pathTimeLerp).clone();
    }

    catPosition.z += parms.catOffset.z;
    catPosition.y += parms.catOffset.y;

    if (mainAnimation.state == "TRACK") {
      catPosition.y -= 0.19 * (1 - tweens.zoomOutFactor);
    }

    cameraPositionFar.add(cameraOffset);
    cameraPositionNormal.lerp(cameraPositionFar, offsetFactor);

    // pull eyepoint more to the cat.
    if (mainAnimation.state == "FENCE") {
      // eyePositionTarget.lerp(catPosition,ofMap(tweens.zoomOutFactor,0,1,0,0.9,true));
    } else if (mainAnimation.state == "TRACK") {
      eyePositionTarget.lerp(
        catPosition,
        ofMap(tweens.zoomOutFactor, 0, 1, 0, 0.2, true)
      );
    }

    if (!settings.freeCam) {

      camera.position.copy(cameraPositionNormal);

      if(settings.hasMouseLook){
        // only for desktop.
        setCameraWithOrbit(cameraPositionNormal);
      }else{
        camera.lookAt(eyePositionTarget);
        if(poolGame) poolGame.update(camera.position,0,0);

      }

    }
    const catVisible = tweens.zoomOutFactor > 0.2 || mainAnimation.state == "FENCE";
    if(catVisible)  setCat(catPosition);
  }

  if (actionPanels){
    actionPanels.isUITriggerOff = settings.noUITriggers;
    actionPanels.update(cameraPosition, eyePosition, settings.freeCam);
  }

 
  const walkDemping = settings.walkDemping;

  const toZeroSpeed = 0.02;
  if (cameraSpeed < toZeroSpeed) cameraSpeed -= cameraSpeed * walkDemping;
  else if (cameraSpeed > toZeroSpeed) cameraSpeed -= cameraSpeed * walkDemping;
  else cameraSpeed = 0;

  const maxSpeed = settings.maxWalkSpeed;
  cameraSpeed = THREE.MathUtils.clamp(cameraSpeed, -maxSpeed, maxSpeed);

  renderer.clear();
  renderer.render(scene, camera);
}




function setCat(catPosition) {
  if (cat.mesh) {
    var camPos = eyePosition.clone();
    var dir = camPos.sub(cameraPosition);

    dir.normalize();
    dir.multiplyScalar(0.5 * catVisibleFactor);

    if (mainAnimation.state == "FENCE") {
      const look = new THREE.Vector3(0, eyePositionTarget.y, -120);
      cat.mesh.lookAt(look);
      cat.mesh.position.copy(catPosition);
    } else if (mainAnimation.state == "EATING") {
      const look = new THREE.Vector3(0, eyePositionTarget.y, -120);
      cat.mesh.lookAt(look);
      cat.mesh.position.copy(catPosition);
    } else {
//      cat.mesh.lookAt(eyePosition);
      cat.mesh.lookAt(eyePosition.x,cat.mesh.position.y,eyePosition.z);

      cat.mesh.position.copy(catPosition);
    }


    const sign = cameraSpeed < 0 ? -1 : 1;
    const offsetFactor2 = ofMap(
      cameraSpeed,
      0,
      settings.maxWalkSpeed * sign,
      0,
      100.0,
      true
    );

    let walkFact = Math.pow(offsetFactor2, 1.0) * 0.03;
    walkFact = Math.abs(walkFact);
    cat.setWalkWeight(THREE.MathUtils.clamp(walkFact, 0, 1));
    
    const div = clock.getDelta() *1.5;
    if (cat) cat.update(div);
  }
}

function setCameraWithOrbit(cameraPositionNormal) {
  //eyePositionTarget
  var eyeTargetTemp = eyePositionTarget.clone();
  var pos = eyeTargetTemp.sub(cameraPositionNormal);
  var radius = pos.length();
  var theta = Math.acos(pos.y / radius);
  var phi = Math.atan2(pos.z, pos.x);

  // lerp target
  mouseOffset.x += (mouseOffsetTarget.x -  mouseOffset.x) * 0.08;
  mouseOffset.y += (mouseOffsetTarget.y -  mouseOffset.y) * 0.08;

  // Subtract deltaTheta and deltaPhi
  theta = Math.min(Math.max(theta - mouseOffset.y, 0), Math.PI);
  phi -= mouseOffset.x;

  // Turn back into Cartesian coordinates
  pos.x = radius * Math.sin(theta) * Math.cos(phi);
  pos.z = radius * Math.sin(theta) * Math.sin(phi);
  pos.y = radius * Math.cos(theta);

  pos.add(eyePositionTarget);
  camera.lookAt(pos);


  if(poolGame){
    const rotationX = phi + Math.PI;
    const rotationY = theta - (Math.PI *0.5);
    poolGame.update(camera.position,pos,rotationX,rotationY);
  }
}

function checkAutoPilot() {
  isAutoPilot = false;
  if (mainAnimation.state == "FENCE") {
 //   isAutoPilot = true;
  //  cameraSpeed += 0.0014;
  }
  if (mainAnimation.state == "EATING") {
    isAutoPilot = true;
    cameraSpeed += 0.0014;
  }
  if (mainAnimation.state == "FENCE_JUMP") {
    isAutoPilot = true;
    cameraSpeed += 0.0008;
  }
 


}

export function start() {
  animate();
}

window.onresize = function () {
  window.onresizeVueFunction();
  if (renderer && camera) {
    camera.aspect = window.innerWidth / window.innerHeight;
    camera.updateProjectionMatrix();

    renderer.setSize(window.innerWidth, window.innerHeight);
  }
};


function adjustLightIntesity(factor){
  ambientLight.intensity = 0.2 * factor;
  directionalLight.intensity = 0.48 * factor;
  directionalLight2.intensity = 1.2 * factor;
}

function setupLighting() {
    ambientLight= new THREE.AmbientLight(0xFFFFFF, 0.2); // soft white light
    scene.add(ambientLight);

    directionalLight = new THREE.PointLight(0xffffff, 0.48,35.1);
    directionalLight.position.set(3, 14, -33);
    scene.add(directionalLight);

    directionalLight2 = new THREE.PointLight(0xceff8a, 1.2,128,2);
    directionalLight2.position.set(-34, 15, 17);
    scene.add(directionalLight2);

  //const sphereSize = 2;//directionalLight.distance;
  //const pointLightHelper = new THREE.PointLightHelper( directionalLight2, sphereSize );
  //scene.add( pointLightHelper );
    
  // const lightFolder = gui.addFolder('Realtime Lighting')
  // lightFolder.close();
  // lightFolder.add(directionalLight.position, 'x', -100, 100).step(1);
  // lightFolder.add(directionalLight.position, 'y', -100, 100).step(1);
  // lightFolder.add(directionalLight.position, 'z', -100, 100).step(1);
  // lightFolder.add(directionalLight , 'intensity', 0.1, 10).step(0.1);
  // lightFolder.add(directionalLight , 'distance', 0.1, 200).step(0.1);

  // lightFolder.add(directionalLight2.position, 'x', -100, 100).step(1);
  // lightFolder.add(directionalLight2.position, 'y', -100, 100).step(1);
  // lightFolder.add(directionalLight2.position, 'z', -100, 100).step(1);
  // lightFolder.add(directionalLight2 , 'intensity', 0.1, 10).step(0.1);
  // lightFolder.add(directionalLight2 , 'distance', 0.1, 200).step(0.1);

}

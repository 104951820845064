import {GLTFLoader} from 'three/examples/jsm/loaders/GLTFLoader.js'
import * as THREE from "three";


class House {

    constructor() {
        this.mesh = null;
        //this.gui =  gui;
        this.gradientMap = null;
        this.poolSurfacePosition = null;
        this.HSLOffset = new THREE.Vector3(0);

        // const tweakPanel = this.gui.addFolder('House')
        // tweakPanel.add(this.HSLOffset, "x", -.2, .2).step(0.001).listen();
        // tweakPanel.add(this.HSLOffset, "y", -.2, .2).step(0.001).listen();
        // tweakPanel.add(this.HSLOffset, "z", -.2, .2).step(0.001).listen();

        // const _this = this;
        // tweakPanel.onFinishChange(()=>{
        //     this.adjustHouseMaterial(_this);
        // });
        
    }

  
    load(callback) {

        const loader = new GLTFLoader();
        const _this = this;


             const modelPath = 'models/bake5/Level_v33.gltf';

             loader.load(modelPath, function (gltfData) {
     
                 const model = gltfData.scene;
                 _this.mesh = model;
                 _this.configureMaterials(_this);
     
                 callback(model);
     
             }, undefined, function (e) {
     
                 console.error(e);
     
             });

       
    }

    // adjustHouseMaterial(_this){
    //    // _this.mesh.traverse(function (o) {
    //         // if (o.name.includes("house") && o.material) {
    //         //     var color = o.material.baseColor.clone();
    //         //     //color.offsetHSL(_this.HSLOffset.x, _this.HSLOffset.y, _this.HSLOffset.z);
    //         //     o.material.color = color;
    //         //     o.material.needsUpdate = true;
    //         // }
    //    // });
    // }

    configureMaterials(_this) {
        _this.mesh.traverse(function (o) {
            if (o.name.includes("Glass") && o.material) {
                const nm = new THREE.MeshPhongMaterial({
                    name: o.material.name,
                    color: o.material.color,
                    opacity: 0.4,
                    shininess: 30,
                    specular: 0xFFFFFF,
                    transparent: true,
                });

                if (o.name.includes("Double")) {
                    nm.side = THREE.DoubleSide;
                }

                o.material = nm;
                nm.needsUpdate = true;
            }

            else if (o.name == "Water" && o.material) {

                _this.poolSurfacePosition = o.position.clone();
                const nm = new THREE.MeshPhongMaterial({
                    color: o.material.color,
                    opacity: 0.7,
                    shininess: 30,
                    specular: 0xEEEEFF,
                    transparent: true,
                });
                o.material = nm;
                nm.needsUpdate = true;
            }


            // switch emmissive map to lightmap because there's no 
            // lightmap field in gltf export.
            else if (o.material && o.material.emissiveMap) {

                var color = o.material.color;
                color.offsetHSL(_this.HSLOffset.x, _this.HSLOffset.y, _this.HSLOffset.z);
                o.name = "house";
                const nm = new THREE.MeshBasicMaterial({
                    color: color,
                    lightMap: o.material.emissiveMap,
                });

                if(o.material.map) nm.map = o.material.map;
                nm.baseColor = color;

                // lightmap only works with second set of UV's
                var uvs = o.geometry.attributes.uv.array;
                o.geometry.setAttribute('uv2', new THREE.BufferAttribute(uvs, 2));
                o.material = nm;
                nm.needsUpdate = true;
            }
            else if (o.name == "Clouds" && o.material) {
                const nm = new THREE.MeshBasicMaterial({
                    fog: false,
                    transparent: true,
                    opacity: 0.3,
                    color: 0x93C8DD
                });

                o.material = nm;
                nm.needsUpdate = true;

            }
        });
    }
}

export { House };
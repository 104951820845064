<template>
  <footer>
    <h2 class="hidden">Copyright information</h2>
    <ul>
      <li>{{ `${$trans("copyrightInfo")} ${new Date().getFullYear()}` }}</li>
      <li v-html="$trans('copyrightPrivacyPolicy')"></li>
      <li v-html="$trans('copyrightCookies')"></li>
      <li v-html="$trans('copyrightPoweredBy')"></li>
    </ul>
  </footer>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style lang="scss">
footer {
  display: flex;
  width: 100%;
  position: relative;

  // &::after {
  //   content: "";
  //   background-image: url("/assets/img/diamond--background.svg");
  //   position: absolute;
  //   left: 50%;
  //   top: -4rem;
  //   background-repeat: no-repeat;
  //   transform: translateX(-50%);
  //   height: 45rem;
  //   width: 45rem;
  //   background-size: cover;
  //   opacity: 0.1;
  //   z-index: -1;
  // }

  ul {
    list-style-type: none;
    padding: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    grid-gap: 1rem;
    margin: 0 auto;

    @include for-tablet-portrait-up {
      justify-content: center;

      li ~ li {
        margin-left: 2rem;
      }
    }

    * {
      color: $color-text;
      font-weight: 600;
      text-transform: uppercase;
      @include fontSize(0.7rem, 1.1, 1.1rem);
    }

    a {
      text-decoration: none;
      transition: color 0.1s linear;
    }
  }

  @include for-giant-desktop-up {
    ul {
      grid-gap: rs(10);

      li ~ li {
        margin-left: rs(20);
      }

      * {
        font-size: rs(11) !important;
      }
    }

    &::after {
      display: none;
    }
  }
}
</style>

import * as THREE from "three";
import { Vector3 } from "three";
import { TWEEN } from 'three/examples/jsm/libs/tween.module.min.js'
import { Fish } from "./Fish.js";
import { ofMap, TriggerEvent } from "./Utils.js";
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import * as SoundController from "@/helpers/sounds";



THREE.Object3D.prototype.updateMatrix = function () {

    this.matrix.compose(this.position, this.quaternion, this.scale);

    if (this.pivot && this.pivot.isVector3) {

        var px = this.pivot.x;
        var py = this.pivot.y;
        var pz = this.pivot.z;

        var te = this.matrix.elements;

        te[12] += px - te[0] * px - te[4] * py - te[8] * pz;
        te[13] += py - te[1] * px - te[5] * py - te[9] * pz;
        te[14] += pz - te[2] * px - te[6] * py - te[10] * pz;

    }

    this.matrixWorldNeedsUpdate = true;

};


class WaterParticle {
    constructor() {
        this.mesh = null;
        this.basePosition;
        this.radius = 0.2 + Math.random() * 0.3;
        this.distance;
        this.maxDistance;
        this.speed;
        this.scale = THREE.MathUtils.randFloat(0.1, 0.32);
        this.rotationDirection = THREE.MathUtils.randFloat(-0.1, 0.1);
        this.height = 0.5 - Math.random();
        this.isActive = false;
        this.height;

        this.reset();
        this.isActive = false;

    }

    reset() {
        this.isActive = true;
        this.height = THREE.MathUtils.randFloat(0.1, 0.2);
        this.distance = THREE.MathUtils.randFloat(0., 0.2);
        this.maxDistance = THREE.MathUtils.randFloat(0.31, 0.8);
        this.speed = THREE.MathUtils.randFloat(1.04, 1.18);

        if (this.mesh) this.mesh.visible = true;
    }
}



class PoolGame {


    constructor(poolSurfacePosition, scene) {
        this.mesh = null;
        this.mixer = null;
        this.animations = null;
        //this.gui = gui;
        this.cube;
        this.poolSurfacePosition = poolSurfacePosition;
        this.fishes = [];
        this.group = new THREE.Group();
        this.hitCenter = new Vector3();
        this.particleCenter = new Vector3();
        this.scene = scene;

        this.particles = [];

        this.armRight;
        this.armLeft;

        this.armRightActive = false;
        this.armLeftActive = false;


        this.armReachLeft = 0;
        this.armReachRight = 0;
        this.armRightTween = null;
        this.armLeftTween = null;

        this.armGroupTween = null;
        this.armGroupOffset = new Vector3(0,0,0);

        this.actionIdle = null;

        this.material = new THREE.MeshStandardMaterial({
            color: 0x060606, opacity: .6,
            transparent: true,
        });

        this.settings = {
            score: 0,
            speed: 0.06,
            jumpCurve: 8,
            isAnimationStarted: false,
            isGameStarted: false,
            hitHeight: -0.12,
        }

        // correction on z to center and x for more to front.
        const offsetCorrection = new THREE.Vector3(0.1, 0, -0.1);

        this.armGroup = new THREE.Group();
        scene.add(this.armGroup);


        // left arm
        const loader = new GLTFLoader();
        const modelPath = 'models/CatFeet.glb';

        var _this = this;
        loader.load(modelPath, function (gltfData) {


            const catColor = new THREE.Color( 0xae5c22 );

            gltfData.scene.traverse(function (o) {
                if(o.material){
                    o.material.color = catColor;
                    o.material.needsUpdate = true;
                }
            });

            // left
            _this.armLeft = gltfData.scene;
            _this.armGroup.add(_this.armLeft);

            _this.armLeft.position.z = 0.2;
            _this.armLeft.scale.set(0.15, 0.15, 0.15);

            // right
            _this.armRight = _this.armLeft.clone();
            _this.armGroup.add(_this.armRight);

            _this.armRight.position.z -= 0.28;
            _this.armRight.scale.set(0.15, 0.15, -0.15);
        });



        this.group.position.copy(poolSurfacePosition.add(offsetCorrection));
        this.setupFish(null);
        this.setupParticles();
        this.hideArms();
    }




    setupFish() {
        const modelPath = 'models/Koi.gltf';
        var _this = this;

        for (let i = -1; i < 1; i++) {

            const fish = new Fish(true);
            fish.lane = 0 + (i * 0.3);

            fish.loadMesh(modelPath, () => {
                _this.group.add(fish.mesh);
                _this.fishes.push(fish);
                const direction = Math.random() < 0.5 ? -1 : 1;
                fish.startMovingWithDelay(direction, 0);
            });

        }

        for (let i = -2; i < 3; i++) {

            const fish = new Fish(false);
            // negative value is more towards fence.             
            fish.lane = -0.2 + (i * 0.14);

            fish.loadMesh(modelPath, () => {
                _this.group.add(fish.mesh);
                _this.fishes.push(fish);
                const direction = Math.random() < 0.5 ? -1 : 1;
                fish.startMovingWithDelay(direction, (3 + i) * 4200);
            });

        }

    }

    startParticles(arm) {
        let position = new THREE.Vector3();
        arm.getWorldPosition(position);
        this.particleCenter = position;
        this.particleCenter.y -= 0.4;
        this.particleCenter.x -= 0.4;

        let activationCount = 0;
        for (var i = 0; i < this.particles.length; i++) {

            var p = this.particles[i];
            if (!p.isActive) {
                p.isActive = true;
                p.reset();
                if (++activationCount > 8) return;
            }

        }
    }

    setupParticles() {

        for (var i = 0; i < 24; i++) {
            const radius = 0.04;
            const segments = THREE.MathUtils.randInt(3, 5);
            const geometry = new THREE.CircleBufferGeometry(radius, segments);

            const opacity = THREE.MathUtils.randFloat(0.6, 1.0);

            const material = new THREE.MeshLambertMaterial({
                color: 0x7DCDFF, opacity: opacity,
                transparent: true,
            });

            const particle = new WaterParticle();
            this.particles.push(particle);
            particle.mesh = new THREE.Mesh(geometry, material);
            particle.mesh.scale.set(particle.scale, particle.scale, particle.scale);
            particle.mesh.rotation.x -= Math.PI * 0.5;
            this.scene.add(particle.mesh);
        }

        this.particles[3].scale = 0.6;
        this.particles[9].scale = 1;
        this.particles[10].scale = 0.7;
    }



    startAnimation() {
        this.settings.isAnimationStarted = true;
    }

    stopAnimation() {
        this.settings.isAnimationStarted = true;
    }

    startGame() {
        this.showArms();
        this.settings.isGameStarted = true;
        console.log("start game");
    }

    stopGame() {
     
        const _this = this;

        this.armGroupTween = new TWEEN.Tween(this.armGroupOffset)
        .to({ x : 0.9 }, 800)
        .start()
        .onComplete(() => {
            _this.armGroup.visible = false;
            _this.settings.isGameStarted = false;
        });
    }

    hideArms(){
        this.armGroup.visible = false;

    }

    showArms(){
        this.armGroup.visible = true;
        this.armGroupOffset.x = .8;

        this.armGroupTween = new TWEEN.Tween(this.armGroupOffset)
        .to({ x : 0 }, 500)
        .start();
    }


    tap() {
        // move arm
        if (!this.settings.isGameStarted) return;

        const leftIsPlaying = this.armLeftTween != null;
        const rightIsPlaying = this.armRightTween != null;
        let allowedToHit = false;

        if (Math.random() < 0.5 && !rightIsPlaying) {
            allowedToHit = true;
            this.armRightTween = this.moveArm(this.armRightTween, { armReachRight: 1 }, { armReachRight: 0 }, this.armRight, () => {
                this.armRightTween = null
            });


        } else if (!leftIsPlaying) {
            allowedToHit = true;
            this.armLeftTween = this.moveArm(this.armLeftTween, { armReachLeft: 1 }, { armReachLeft: 0 }, this.armLeft, () => {
                this.armLeftTween = null
            });
        }

        if (allowedToHit) {
            for (var i = 0; i < this.fishes.length; i++) {
                let fish = this.fishes[i];
                if (!fish.isHit && fish.mesh.position.y > this.settings.hitHeight) {
                    fish.hit();
                    this.settings.score++;
                    TriggerEvent("score-whackakoi", { detail: { number: this.settings.score } });
                }
            }

            if(Math.random() < 0.5){
                SoundController.play("water1");
            }else{
                SoundController.play("water2");   
            }

        }
    }


    moveArm(armTween, armReachParameter1, armReachParameter2, armMesh, callback) {
        var _this = this;

        armTween = new TWEEN.Tween(_this)
            .to(armReachParameter1, 300).easing(TWEEN.Easing.Elastic.Out)
            .start()
            .onComplete(() => {
                _this.startParticles(armMesh);
                armTween.stop();
                armTween = new TWEEN.Tween(this)
                    .to(armReachParameter2, 400).start()
                    .onComplete(() => {
                        callback();
                    })
            });

        return armTween;
    }

    update(cameraPosition, lookAt, rotation, rotationY) {

        if (!this.settings.isAnimationStarted) return;

        for (var j = 0; j < this.fishes.length; j++) {
            var f = this.fishes[j];
            f.update();
        }

        if (!this.settings.isGameStarted) return;

        // check if loaded.
        if (this.armRight) {
            this.armGroup.position.copy(cameraPosition);
            // move arms a bit down.
            this.armGroup.position.y -= .5;
            // used for tweening the arms in and out.
            this.armGroup.position.x += this.armGroupOffset.x;

            this.armGroup.rotation.y = -rotation;
            this.armGroup.rotation.z = rotationY;
            this.armGroup.rotation.z = 0.4;

            let armPos = new THREE.Vector3(0, 0, 0);
            armPos.x += ofMap(this.armReachRight, 0, 1, 0.5, -0.01, true);
            armPos.y += ofMap(this.armReachRight, 0, 1, 0.2, -0.22, true);
            armPos.z = -.15;
            this.armRight.position.copy(armPos);
            this.armRight.rotation.y = (0.1);

            let armPos2 = new THREE.Vector3(0, 0, 0);
            armPos2.x += ofMap(this.armReachLeft, 0, 1, 0.5, -0.01, true);
            armPos2.y += ofMap(this.armReachLeft, 0, 1, 0.2, -0.22, true);
            armPos2.z = .12;
            this.armLeft.position.copy(armPos2);
        }


        // particles
        const time = Date.now();
        for (var i = 0; i < this.particles.length; i++) {

            var p = this.particles[i];
            if (!p.isActive) continue;

            const speed = i + (time * 0.001 * p.rotationDirection);
            p.distance *= p.speed;
            p.y = p.distance;

            if (p.distance > p.maxDistance) {
                p.isActive = false;
                p.mesh.visible = false;
            }

            var x = Math.sin(speed) * p.distance;
            var z = Math.cos(speed) * p.distance;

            p.height -= (0.008 * p.speed);
            var newPos = new THREE.Vector3(x, p.height, z);
            newPos.add(this.particleCenter);
            p.mesh.position.copy(newPos);
            p.mesh.rotation.z = Math.PI * 0.45;
            if (p.speed > 1.03) p.speed -= p.speed * 0.01;
        }
    }

    hide() {
        if (this.mesh) this.mesh.visible = false;
    }

    show() {
        if (this.mesh) this.mesh.visible = true;
    }

    load(callback) {
        callback();
        // const gamePanel = this.gui.addFolder('Game')
        // gamePanel.add(this.settings, "score", 0, 10).step(0.001).listen();
        // gamePanel.add(this.settings, "speed", 0, 0.3).step(0.001);
        // gamePanel.add(this.settings, "hitHeight", -0.2, 0.).step(0.001);
    }

}

export { PoolGame };


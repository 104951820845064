<template>
  <div class="prompt prompt__koi">
    <transition-group name="fade">
      <p class="prompt__koi__count scale" v-if="currentPrompt === 1" key="1">
        {{ $trans("koiFirstPrompt") }}
      </p>
      <p class="prompt__koi__count scale" v-if="currentPrompt === 2" key="2">
        {{ $trans("koiSecondPrompt") }}
      </p>
      <p class="prompt__koi__count scale" v-if="currentPrompt === 3" key="3">
        {{ $trans("koiThirdPrompt") }}
      </p>
      <!-- GO! -->
      <p class="prompt__koi__count big" v-if="currentPrompt === 4" key="4">
        {{ $trans("koiFourthPrompt") }}
      </p>
    </transition-group>

    <transition name="slide">
      <div class="prompt__koi__intro" v-if="this.currentPrompt === 0">
        <h2>{{ $trans("koiIntroTitle") }}</h2>
        <p>{{ $trans("koiIntroText") }}</p>
        <div class="prompt__koi__actions">
          <Button
            @click.native="handleSkipClick()"
            class="button--outline skip"
          >
            {{ $trans("koiSkip") }}
          </Button>
          <Button
            @click.native="handleClick()"
            class="button--white"
            :arrow="true"
            arrowColor="orange"
          >
            {{ $trans("koiStart") }}
          </Button>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import Button from "@/components/buttons/Button";

export default {
  name: "KoiPrompt",
  components: {
    Button,
  },
  data() {
    return {
      currentPrompt: 0,
    };
  },
  mounted() {},
  methods: {
    handleClick() {
      this.$emit('count-down');
      this.currentPrompt = 1;
      let interval = setInterval(() => {
        this.currentPrompt++;
        if (this.currentPrompt == 6) {
          this.$store.state.currentState = this.$uiState.PLAYING;
          this.$store.state.currentGameState = this.$gameState.FISHING;
          this.$emit("start-whackakoi");
          clearInterval(interval);
        }
      }, 1000);
    },
    handleSkipClick() {
      this.$store.state.currentState = this.$uiState.PLAYING;
      this.$store.state.currentGameState = this.$gameState.WALKING;
      this.$emit("skip-whackakoi");
    },
  },
};
</script>

<style lang="scss" scoped>
.prompt__koi {
  overflow: hidden;
  // background: rgba(#0079b9, 0.5);
  // pointer-events: none;
  display: grid;
  place-content: center;

  *:not(.button) {
    color: $color-text--inverse;
    pointer-events: all;
  }

  &__intro {
    padding: 0 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    max-width: 90%;
    margin: 0 auto;

    h2 {
      @include fontSize(3.6rem, 4.6, 4.6rem, 1);
    }

    p {
      @include fontSize(1.8rem, 2.8, 2.8rem, 1.28);
      margin: 3.2rem auto 4.8rem auto;
    }

    @include for-tablet-landscape-up {
      max-width: 33%;

      p {
        max-width: 100%;
      }
    }
  }

  &__count {
    position: absolute;
    font-weight: 600;
    @include fontSize(4.8rem, 6.2, 6.2rem, 1);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 0;
    display: grid;
    place-content: center;
    text-transform: uppercase;

    &.big {
      transform: scale(2);
    }

    &.fade-enter-active,
    &.fade-leave-active {
      transition: all $basic-transition-1000;
    }

    &.fade-enter,
    &.fade-leave-to {
      opacity: 0;
    }

    &.fade-leave-to.scale {
      transform: scale(10);
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    grid-gap: 1.6rem;

    * {
      margin: 0;
      min-width: 17rem;
    }

    .skip {
      display: flex;
      align-items: center;
      justify-content: center;
      order: 2;
    }

    @include for-tablet-landscape-up {
      flex-direction: row;

      .skip {
        order: unset;
      }
    }
  }

  .slide-enter-active,
  .slide-leave-active {
    transition: all $basic-transition-500;
    transform: translateY(-50%);
  }

  .slide-enter,
  .slide-leave-to {
    opacity: 0;
    transform: translateY(-50%);
  }

  @include for-giant-desktop-up {
    &__intro {
      padding: 0 rs(20);

      h2 {
        font-size: rs(46) !important;
      }

      p {
        font-size: rs(28) !important;
        margin: rs(32) auto rs(48) auto;
      }
    }

    &__count {
      font-size: rs(62) !important;
    }

    &__actions {
      grid-gap: rs(16);

      * {
        min-width: rs(170);
      }
    }
  }
}
</style>

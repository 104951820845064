var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('transition',{attrs:{"name":"slide-cookie-down"}},[(_vm.showCookieForm)?_c('CookieBanner',{on:{"accepted":function($event){_vm.showCookieForm = false}}}):_vm._e()],1),_c('UserInterface',{attrs:{"state":_vm.currentState},on:{"start-loading":function($event){return _vm.setupScene()},"finish-loading":function($event){_vm.showGame = true},"allow-interaction":function($event){return _vm.CatQuest.interactionAllowed()},"finish-quiz":function($event){return _vm.CatQuest.finishQuiz()},"skip-whackakoi":function($event){return _vm.CatQuest.skipWhackAKoi()},"start-whackakoi":function($event){return _vm.CatQuest.startWhackAKoi()},"end-whackakoi":function($event){return _vm.CatQuest.endWhackAKoi()},"finish-whackakoi":function($event){return _vm.CatQuest.finishWhackAKoi()},"finish-game":function($event){_vm.showGame = false}}}),_c('canvas',{class:_vm.showGame ? 'game--animate' : '',attrs:{"id":"canvas"}}),_c('div',{attrs:{"id":"statsContainer"}}),(!_vm.production)?_c('div',{staticClass:"temp"},[_c('button',{on:{"click":function($event){_vm.$store.state.currentState = _vm.$uiState.INIT;
        _vm.$store.state.currentGameState = _vm.$gameState.PAUSED;}}},[_vm._v(" Start screen ")]),_c('button',{on:{"click":function($event){_vm.$store.state.currentState = _vm.$uiState.LOADING;
        _vm.$store.state.currentGameState = _vm.$gameState.PAUSED;
        _vm.setupScene();}}},[_vm._v(" Load screen ")]),_c('button',{on:{"click":function($event){_vm.$store.state.currentState = _vm.$uiState.QUIZ;
        _vm.$store.state.currentGameState = _vm.$gameState.PAUSED;}}},[_vm._v(" Quiz screen ")]),_c('button',{on:{"click":function($event){_vm.$store.state.currentState = _vm.$uiState.PLAYING;
        _vm.$store.state.currentGameState = _vm.$gameState.INTRO;}}},[_vm._v(" Intro prompt ")]),_c('button',{on:{"click":function($event){_vm.$store.state.currentState = _vm.$uiState.PLAYING;
        _vm.$store.state.currentGameState = _vm.$gameState.FISHING_INTRO;}}},[_vm._v(" Koi prompt ")]),_c('button',{on:{"click":function($event){_vm.$store.state.currentState = _vm.$uiState.PLAYING;
        _vm.$store.state.currentGameState = _vm.$gameState.FINISHED_INTRO;}}},[_vm._v(" End prompt ")]),_c('button',{on:{"click":function($event){_vm.$store.state.currentState = _vm.$uiState.FINISHED;
        _vm.$store.state.currentGameState = _vm.$gameState.FINISHED;}}},[_vm._v(" Finished screen ")]),_c('button',{on:{"click":function($event){_vm.$store.state.currentState = -1;
        _vm.$store.state.currentGameState = -1;
        _vm.showGame = true;
        _vm.setupScene();}}},[_vm._v(" -- HIDE UI -- ")])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }
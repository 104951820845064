<template>
  <div :class="`end-slide`">
    <section class="prize">
      <h2>{{ $trans("formTitle") }}</h2>
      <h3 v-html="$trans('formSubtitle')"></h3>

      <div class="swiper">
        <div class="swiper-wrapper">
          <div
            v-for="(prize, index) in prizes"
            :key="index"
            class="swiper-slide img__wrapper swiper-no-swiping"
          >
            <img
              :src="`assets/img/prizes/prize${index + 1}.png`"
              alt="Prize image"
            />
          </div>
        </div>
        <p>{{ $trans("formPrize") }}</p>
      </div>
    </section>

    <section class="form" id="form">
      <form @submit.prevent="submitForm">
        <div>
          <h3>{{ $trans("formCatData") }}</h3>
          <TextInput
            :label="$trans('formName')"
            name="name"
            :placeholder="$trans('formNamePlaceholder')"
            v-model="form.cat.name"
            :error="errors.cat.name"
            @clear-error="errors.cat.name = null"
          />
          <SelectInput
            :label="$trans('formAge')"
            name="age"
            :placeholder="$trans('formAgePlaceholder')"
            v-model="form.cat.age"
            :selected="form.cat.age"
            :data="ages"
            :error="errors.cat.age"
            @clear-error="errors.cat.age = null"
          />
          <TextInput
            :label="$trans('formFavorite')"
            name="favorite"
            :placeholder="$trans('formFavoritePlaceholder')"
            v-model="form.cat.favorite"
            :error="errors.cat.favorite"
            @clear-error="errors.cat.favorite = null"
          />
        </div>
        <div class="divider"></div>
        <div>
          <h3>{{ $trans("formUserData") }}</h3>
          <TextInput
            :label="$trans('formFullName')"
            name="fullname"
            :placeholder="$trans('formFullNamePlaceholder')"
            v-model="form.fullname"
            :error="errors.fullname"
            @clear-error="errors.fullname = null"
          />
          <TextInput
            :label="$trans('formEmail')"
            name="email"
            :placeholder="$trans('formEmailPlaceholder')"
            v-model="form.email"
            type="email"
            :error="errors.email"
            @clear-error="errors.email = null"
          />
          <Checkbox
            :label="$trans('formTos')"
            name="consent"
            v-model="form.consent"
            :error="errors.consent"
            @clear-error="errors.consent = null"
          />
        </div>
      </form>
      <div class="actions">
        <Button
          @click.native="submitForm()"
          class="button--white button--shadow"
          :arrow="true"
          arrowColor="green"
        >
          {{ $trans("formSubmitButton") }}
        </Button>
        <a href="#!" @click.prevent="handleReplayClick()">
          {{ $trans("scoreNoThanks") }}
        </a>
      </div>
    </section>
  </div>
</template>

<script>
import Swiper, { Autoplay, EffectCreative } from "swiper";
import { parseEmail } from "@/helpers";
import { TextInput, Checkbox, SelectInput } from "@/components/inputs";
import Button from "@/components/buttons/Button";

export default {
  name: "FirstSlide",
  components: {
    Button,
    TextInput,
    Checkbox,
    SelectInput,
  },
  data() {
    return {
      prizes: [1, 2, 3, 4, 5],
      ages: this.$trans("formAgeOptions").map((option) => {
        return option.age;
      }),
      form: {
        fullname: this.user ? this.user.userFullName : null,
        email: this.user ? this.user.userEmail : null,
        consent: null,
        cat: {
          name: this.user ? this.user.userCatName : null,
          age: this.user ? this.user.userCatAge : null,
          favorite: this.user ? this.user.userCatFavorite : null,
        },
      },
      errors: {
        fullname: null,
        email: null,
        consent: null,
        cat: {
          name: null,
          age: null,
          favorite: null,
        },
      },
    };
  },
  mounted() {
    Swiper.use([Autoplay, EffectCreative]);
    new Swiper(".swiper", {
      loop: true,
      slidesPerView: 1,
      centeredSlides: true,
      autoplay: {
        delay: 2000,
      },
      effect: "creative",
      creativeEffect: {
        prev: {
          translate: ["-25%", 0, 0],
          opacity: 0,
        },
        next: {
          translate: ["25%", 0, 0],
          opacity: 0,
        },
      },
    });
  },
  methods: {
    submitForm() {
      let valid = this.validateEntries();
      if (valid) {
        this.$emit("continue", this.form);
      } else {
        document
          .getElementById("form")
          .scrollTo({ top: 0, behavior: "smooth" });
      }
    },
    validateEntries() {
      let valid = true;
      let f = this.form;
      let c = this.form.cat;
      let e = this.errors;
      if (!f.fullname) {
        e.fullname = this.$trans("errorFullName") ?? true;
        valid = false;
      }
      if (!f.email || !parseEmail(f.email)) {
        e.email = this.$trans("errorEmail") ?? true;
        valid = false;
      }
      if (f.consent !== true) {
        e.consent = this.$trans("errorTos") ?? true;
        valid = false;
      }
      if (!c.name) {
        e.cat.name = this.$trans("errorName") ?? true;
        valid = false;
      }
      if (!c.age || !this.ages.includes(c.age)) {
        e.cat.age = this.$trans("errorAge") ?? true;
        valid = false;
      }
      if (!c.favorite) {
        e.cat.favorite = this.$trans("errorFavorite") ?? true;
        valid = false;
      }
      return valid;
    },
    handleReplayClick() {
      this.sendGtmEvent("button-click", {
        target: "replay-button",
        value: this.$trans("scoreNoThanks"),
        page: "form_page",
        language: localStorage.getItem("country"),
      });
      this.$emit("replay");
    },
  },
};
</script>

<style lang="scss" scoped>
.end-slide {
  display: grid;
  grid-template: 1fr 1fr / 1fr;
  padding: 9.6rem 1.6rem 1.6rem 1.6rem;
  overflow-y: auto;
  overflow-x: hidden;

  h2 {
    @include fontSize(3.2rem, 3.2, 3.2rem, 1);
    text-transform: uppercase;
    font-weight: 600;
    margin-bottom: 2.4rem;
  }

  h3 {
    @include fontSize(1.8rem, 1.8, 1.8rem, 1.5);
    font-weight: normal;
  }

  .prize {
    position: relative;
    height: 70vh;

    .swiper {
      margin-top: 4.2rem;
      width: 100%;
      position: absolute;

      * {
        width: 100%;
      }

      p {
        @include fontSize(1.8rem, 1.8, 1.8rem, 1.5);
        text-transform: uppercase;
        color: $color-text--inverse;
        font-weight: 600;
      }
    }
  }

  .form {
    margin-bottom: 12rem;

    * {
      text-align: left;
    }

    form {
      position: relative;
      display: grid;
      grid-template: auto / 1fr;
      grid-gap: 4.2rem;
      margin-bottom: 4.2rem;

      h3 {
        @include fontSize(2.8rem, 3, 3rem);
        font-weight: lighter;
        margin: 0;
      }
    }

    .divider {
      display: block;
      height: 0.2rem;
      width: 100%;
      background: $color-blue--secondary;
      opacity: 0.1;

      @include for-tablet-landscape-up {
        display: none;
      }
    }
  }

  .actions {
    display: flex;
    flex-direction: column;
    align-items: center;

    button {
      margin-bottom: 2.4rem;
      width: 100%;
      margin: 0 auto;
      box-shadow: inset 0 1rem 3rem 0 rgba(0, 0, 0, 0.1);
      background: #ceeab8;

      &:hover {
        box-shadow: inset 0 0 0 0 rgba(0, 0, 0, 0);
      }

      &::after {
        border-radius: inherit;
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 100%;
        transition: all 0.1s ease-out;
        box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
      }
    }

    a {
      color: $color-text;
      line-height: 2.4rem;
      text-decoration: none;
      border-bottom: 0.1rem solid $color-text;
      margin-top: 4.2rem;

      &:hover {
        color: #000;
        border-bottom: 0.1rem solid #000;
      }
    }
  }

  @include for-tablet-landscape-up {
    grid-template: 1fr / 1fr 1fr;
    padding: unset;

    h2 {
      font-size: rs(32) !important;
      margin-bottom: rs(24);
    }

    h3 {
      font-size: rs(18) !important;
    }

    .prize {
      padding: rs(46) 0;
      margin: auto 0;

      .swiper {
        width: 65%;
        left: 50%;
        transform: translateX(-50%);
        margin-top: rs(42);

        p {
          font-size: rs(18) !important;
        }
      }
    }

    .form {
      padding: unset;
      margin: auto 0;

      * {
        max-width: rs(330);
      }

      form {
        grid-gap: rs(24);
        margin-bottom: rs(24);

        h3 {
          font-size: rs(21) !important;
        }

        .select-input {
          margin-top: rs(5) !important;
        }

        .check-input {
          margin-top: rs(24) !important;

          * {
            font-size: rs(14) !important;
          }
        }
      }

      a {
        font-size: rs(14) !important;
        margin-top: rs(24);
      }
    }

    // .actions {
    //   display: flex;
    //   flex-direction: column;
    //   align-items: center;

    //   button {
    //     margin-bottom: 2.4rem;
    //     width: 100%;
    //     margin: 0 auto;
    //     box-shadow: inset 0 1rem 3rem 0 rgba(0, 0, 0, 0.1);
    //     background: #ceeab8;

    //     &:hover {
    //       box-shadow: inset 0 0 0 0 rgba(0, 0, 0, 0);
    //     }

    //     &::after {
    //       border-radius: inherit;
    //       content: "";
    //       position: absolute;
    //       left: 0;
    //       right: 0;
    //       bottom: 0;
    //       height: 100%;
    //       transition: all 0.1s ease-out;
    //       box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
    //     }
    //   }

    //   a {
    //     color: $color-text;
    //     line-height: 2.4rem;
    //     text-decoration: none;
    //     border-bottom: 0.1rem solid $color-text;
    //     margin-top: 4.2rem;

    //     &:hover {
    //       color: #000;
    //       border-bottom: 0.1rem solid #000;
    //     }
    //   }
    // }
  }
}
</style>

import * as THREE from 'three/build/three.module.js';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { TWEEN } from 'three/examples/jsm/libs/tween.module.min.js'
import { TriggerEvent } from "./Utils.js";

class Particle {
    constructor() {
        this.mesh = null;
        this.basePosition;
        this.radius = 0.2 + Math.random() * 0.3;

        this.speed = 1. + Math.random() * 3.1;
        this.scale = 0.1 + Math.abs((Math.random() * .2));
//        if(this.scale > 0.3) this.scale *= 3;
        this.height = 0.5 - Math.random();

    }
}


class ActionPanels {

    constructor(mainAnimation) {


        this.mesh = null;
        this.basePosition;
        this.clock = new THREE.Clock();
        this.tweenInOut;
        this.rotationInc = 0;
        this.rotationTween;
        this.panelGui;
        this.material;

        this.isOpen = false;
        this.positions = [];
        this.baseRotations = [];
        this.particles = [];

        /// for debugging
        this.guiPositionIndex = 0;
        this.guiPositionItem;
        //this.gui = gui;
        this.isTweening = false;
        this.isUITriggerOff = false;

        this.offsetYTween = null;
        this.offsetYValue = 0;

        this.opacityTween = null;

        // positions
        this.positionIndex = 0;
        this.baseRotation = 0;


        this.material = new THREE.MeshLambertMaterial({
            color: 0xffc524, opacity: 0.0,
            transparent: true,
        });


        this.materialParticle = new THREE.MeshToonMaterial({
            color: 0xfad15f, opacity: .18,
            transparent: true,
            side: THREE.DoubleSide,
            emissive: 0xfad15f,
            emissiveIntensity: 1, 
        });


        this.setupPositions(mainAnimation);
        this.setupGui();
        this.openQuizCallback = null;


    }

    addOffset(x,y,z){
        var pos = this.positions[this.positions.length-1];
        pos.x += x;
        pos.y += y;
        pos.z += z;
    }


    setupPositions(mainAnimation) {
      
        //1
        this.positions.push(mainAnimation.getPositionAtTime(240).clone());
        this.addOffset(-0.114,0,0);
        this.baseRotations.push(1);

        //2
        this.positions.push(mainAnimation.getPositionAtTime(358).clone());
        this.addOffset(0,-0.054,0.225);
        this.baseRotations.push(3);

        // 3
        this.positions.push(mainAnimation.getPositionAtTime(500).clone());
        this.addOffset(0,-0.2,0.225);
        this.baseRotations.push(-1.8);

        //4
        this.positions.push(mainAnimation.getPositionAtTime(920).clone());
        this.baseRotations.push(1.73);

        //5
        this.positions.push(mainAnimation.getPositionAtTime(1225).clone());
        this.addOffset(0,-0.05,0.0);
        this.baseRotations.push(-1.8);

        //6
        this.positions.push(mainAnimation.getPositionAtTime(1323).clone());
        this.baseRotations.push(-1.8);

        // 7
        this.positions.push(mainAnimation.getPositionAtTime(1430).clone());
        this.baseRotations.push(-1.8);

        //8
        this.positions.push(mainAnimation.getPositionAtTime(1612).clone());
        this.addOffset(0,-0.2,0.0);
        this.baseRotations.push(-0.13);

        //9
        this.positions.push(mainAnimation.getPositionAtTime(1830).clone());
        this.baseRotations.push(-4.63);

        //10
        this.positions.push(mainAnimation.getPositionAtTime(1970).clone());
        this.addOffset(0,-0.1,0.0);
        this.baseRotations.push(-4.33);

    }

    getRestartTimeAfterQuiz(){
        switch(this.positionIndex){
            case 0: 
                return 2047;
            case 1: 
                return 230;
            case 2: 
                return 390;
            case 3: 
                return 591;
            case 4: 
                return 906;
            case 5: 
                return 1221;
            case 6: 
                return 1345;
            case 7: 
                return 1450;
            case 8: 
                return 1667;
            case 9: 
                return 1896;
            case 10: 
                return 2047;
        }
    }


    setupParticles(scene) {

        const radius = 0.04;
       // const geometry = new THREE.DodecahedronBufferGeometry(radius);


        //const radius = 7;
        const segments = 3;
         const geometry = new THREE.CircleBufferGeometry(radius, segments);
        // const geometry = new THREE.IcosahedronBufferGeometry(radius);
        // const geometry = new THREE.OctahedronBufferGeometry(radius);
       // const widthSegments = 12;
        //const heightSegments = 8;
        //   const geometry = new THREE.SphereBufferGeometry(radius, widthSegments, heightSegments);

        for (var i = 0; i < 12; i++) {
            const particle = new Particle();
            this.particles.push(particle);
            particle.mesh = new THREE.Mesh(geometry, this.materialParticle);
            particle.mesh.scale.set(particle.scale, particle.scale, particle.scale);
            scene.add(particle.mesh);
        }

        this.particles[3].scale = 0.6;
        this.particles[9].scale = 1;
        this.particles[10].scale = 0.7;
    }

    setupGui() {

        //this.guiPositionItem = this.positions[this.positionIndex];
      //  this.guiPositionItem = new THREE.Vector3(0,0,0);
      //  var _this = this;

        // this.panelGui = this.gui.addFolder('Diamands')
        // this.panelGui.close();
        
        // this.panelGui.add({ x: () => { _this.printPositions(_this.positions) } }, 'x').name(`print positions`);

        //  this.panelGui.add(this.guiPositionItem, 'x', -1, 1).step(0.001);
        //  this.panelGui.add(this.guiPositionItem, 'y', -1, 1).step(0.001);
        //  this.panelGui.add(this.guiPositionItem, 'z', -1, 1).step(0.001);
        //  this.panelGui.add(this, 'baseRotation', -3.14, 3.14).step(0.01);

    }

    // for changing the positions
    printPositions(arg) {
        for (var i = 0; i < arg.length; i++) {
            console.log("this.positions.push(new THREE.Vector3(" + arg[i].x + "," + arg[i].y + "," + arg[i].z + "));");
        }
    }


    setNumber(value){
        this.mesh.traverse(function (o) {
            //o.material = _this.material;
            if(o.name == (value)){
               o.visible = true;
            }else if(!o.name.includes("Panel")){
                o.visible = false;
            }

        });
    }

    load(callback) {

        const loader = new GLTFLoader();
        const _this = this;
        const modelPath = 'models/ActionPanel.glb';

        loader.load(modelPath, function (gltfData) {

            const model = gltfData.scene;

            _this.mesh = model;
            _this.mesh.scale.set(0, 0, 0);

             _this.mesh.traverse(function (o) {
                if(o.material){
                    const nm = new THREE.MeshStandardMaterial({
                        color: o.material.color,
                        emissive: o.material.color,
                        emissiveIntensity: 1, 
                    });
                    o.material = nm;

                    if(!o.name.includes("Panel")){
                        o.visible = false;
                    }
                 }
             });

             _this.setNumber("1");

            callback(model);

        }, undefined, function (e) {

            console.error(e);

        });
    }


    setNewPositionIndex(value){
        const numberString = "" + (value + 1).toString();
        this.setNumber(numberString);
        this.positionIndex = value;
    
    }


    update(cameraPosition, eyePosition) {


        const time = this.clock.getElapsedTime();
        // Movement
        let offset = Math.sin(time * 1.4);
        let offVec = new THREE.Vector3(0, offset * 0.04, 0);

        this.basePosition = this.positions[this.positionIndex];

        if (this.mesh) {
            offVec.add(this.basePosition);
          //  offVec.add(this.guiPositionItem);

            offVec.y += this.offsetYValue;
            this.mesh.position.copy(offVec);
            // full rotation
            //this.mesh.rotation.y += 0.02 + this.rotationInc;
            // wiggle rotation
            const baseRotation =  this.baseRotation +  this.baseRotations[this.positionIndex];
            this.mesh.rotation.y = baseRotation + (Math.sin(time) * 0.4);

            if (!this.isTweening) {


                // check visual hit ( dot product)
                const camVec = new THREE.Vector2(eyePosition.x - cameraPosition.x, eyePosition.z - cameraPosition.z);
                const meshVec = new THREE.Vector2(this.mesh.position.x - cameraPosition.x, this.mesh.position.z - cameraPosition.z);

                const dotP = camVec.dot(meshVec);

                // check distance
                const camVec2 = new THREE.Vector2(cameraPosition.x, cameraPosition.z);
                const meshVec2 = new THREE.Vector2(this.mesh.position.x, this.mesh.position.z);


                const distance = camVec2.distanceToSquared(meshVec2);

               // this.debug("dist: " + distance + " dopp: " + dotP);


                if (distance < 1 && !this.isUITriggerOff) {

                   // this.guiPositionItem = this.positions[this.positionIndex];
                    //console.log("Hit on: " + this.positionIndex);

                    this.sendIndex(this.positionIndex + 1);
                    if(this.openQuizCallback) this.openQuizCallback();

                    this.TweenOut(() => {

                        // NEW NUMBER
                        this.positionIndex++;
                        if (this.positionIndex >= this.positions.length) this.positionIndex = 0;

                        this.setNewPositionIndex(this.positionIndex);

                       // console.log("callback new pos: " + this.positionIndex);

                        // this.guiPositionItem = this.positions[this.positionIndex];
                        // this.panelGui.close();
                        // this.panelGui.add(this.guiPositionItem, 'x', -10, 10).step(0.01);
                        // this.panelGui.add(this.guiPositionItem, 'y', -10, 10).step(0.01);
                        // this.panelGui.add(this.guiPositionItem, 'z', -20, 18).step(0.01);
                        this.isTweening = false;

                    });


                    return;
                }

                if (dotP < 30 && distance < 40) {
                    if (!this.isOpen) {
                        this.mesh.visible = true;
                        this.TweenIn();
                    }

                } else {
                    if (this.isOpen) {
                        this.TweenOut();
                        this.isActive = false;
                    }
                }
            }
        }

        // Particle animation
        if (this.isOpen) {
            for (var i = 0; i < this.particles.length; i++) {
                var newPos = offVec.clone();
                var p = this.particles[i];

                var speed = (p.speed) * (i + time ) * 0.3;
                const distance = 0.2 + (0.5 * p.radius)  * this.mesh.scale.x;

                var x = Math.sin(speed) * distance;
                var z = Math.cos(speed) * distance;
                var y = Math.cos(speed * 0.96) * distance * 0.26;

                newPos.x += x;
                newPos.z += z;
                newPos.y += 0.2 + y + (p.height * 0.2);

                var scaleVar = Math.sin(i + time *0.5);
                p.mesh.scale.x = this.mesh.scale.x * p.scale * scaleVar;
                p.mesh.scale.y = this.mesh.scale.y * p.scale *scaleVar;
                p.mesh.scale.z = this.mesh.scale.z * p.scale *scaleVar;
                p.mesh.position.copy(newPos);

                this.materialParticle.opacity = this.material.opacity * 0.5;

                p.mesh.rotation.y += (speed *  0.0008);
                p.mesh.rotation.z += (speed *  0.0002);
                p.mesh.rotation.x += (speed *  0.0002);

            }
        }






    }


    sendIndex(index){

        TriggerEvent("open-quiz",{
            detail: { number: index },
        });
    }

    TweenIn() {
        if(this.isTweening) return;
        //scale
        const animationTime = 180.5;
        this.isOpen = true;
        this.tweenInOut = new TWEEN.Tween(this.mesh.scale)
            .to(
                { x: 1, y: 1, z: 1 }
                ,
                animationTime
            )
            //.easing(TWEEN.Easing.Bounce.Out)
            .easing(TWEEN.Easing.Elastic.Out)
            .start()
            .onComplete(() => {
                this.isTweening = false;

            });

        // rotation
        this.rotationInc = 0.12;
        this.rotationTween = new TWEEN.Tween(this)
            .to(
                { rotationInc: 0.0 }
                ,
                animationTime * 12
            )
            .easing(TWEEN.Easing.Circular.Out)
            .start();


        this.offsetYValue = -0.22;
        this.offsetYTween = new TWEEN.Tween(this)
            .to(
                { offsetYValue: 0.0 }
                ,
                animationTime * 4
            )
            .easing(TWEEN.Easing.Elastic.Out)
            .start();


        if (this.opacityTween) this.opacityTween.stop();
        this.material.opacity = 0;
        this.opacityTween = new TWEEN.Tween(this.material)
            .to(
                { opacity: 1.0 }
                ,
                animationTime * 1.8
            )
            .easing(TWEEN.Easing.Circular.InOut)
            .start();

    }


    TweenOut(callback) {
        if(this.isTweening) return;
        const animationTime = 610.5;
        this.isTweening = true;

        this.tweenInOut = new TWEEN.Tween(this.mesh.scale)
            .to(
                { x: 1.2, y: 1.2, z: 1.2 }
                ,
                animationTime
            )
            .easing(TWEEN.Easing.Quadratic.Out)
            .start()
            .onComplete(() => {
                if (callback) callback();
                this.isOpen = false;
                this.isTweening = false;

            });



        // this.offsetYTween = new TWEEN.Tween(this)
        //     .to(
        //         { offsetYValue: -0.22 }
        //         ,
        //         animationTime
        //     )
        //     .easing(TWEEN.Easing.Circular.InOut)
        //     .start();

        if (this.opacityTween) this.opacityTween.stop();
        this.opacityTween = new TWEEN.Tween(this.material)
            .to(
                { opacity: 0.0 }
                ,
                animationTime * 0.6
            )
            .easing(TWEEN.Easing.Quadratic.Out)
            .start();



    }



    setPosition(basePosition) {
        this.basePosition = basePosition;
    }
}

export { ActionPanels };
<template>
  <div>
    <!-- User interfaces -->
    <transition name="slide-cookie-down">
      <CookieBanner v-if="showCookieForm" @accepted="showCookieForm = false" />
    </transition>
    <UserInterface
      :state="currentState"
      @start-loading="setupScene()"
      @finish-loading="showGame = true"
      @allow-interaction="CatQuest.interactionAllowed()"
      @finish-quiz="CatQuest.finishQuiz()"
      @skip-whackakoi="CatQuest.skipWhackAKoi()"
      @start-whackakoi="CatQuest.startWhackAKoi()"
      @end-whackakoi="CatQuest.endWhackAKoi()"
      @finish-whackakoi="CatQuest.finishWhackAKoi()"
      @finish-game="showGame = false"
    />

    <!-- Game world -->
    <canvas id="canvas" :class="showGame ? 'game--animate' : ''"></canvas>

    <!-- TODO: Temporary controls -->
    <div id="statsContainer"></div>
    <div class="temp" v-if="!production">
      <button
        @click="
          $store.state.currentState = $uiState.INIT;
          $store.state.currentGameState = $gameState.PAUSED;
        "
      >
        Start screen
      </button>
      <button
        @click="
          $store.state.currentState = $uiState.LOADING;
          $store.state.currentGameState = $gameState.PAUSED;
          setupScene();
        "
      >
        Load screen
      </button>
      <button
        @click="
          $store.state.currentState = $uiState.QUIZ;
          $store.state.currentGameState = $gameState.PAUSED;
        "
      >
        Quiz screen
      </button>
      <button
        @click="
          $store.state.currentState = $uiState.PLAYING;
          $store.state.currentGameState = $gameState.INTRO;
        "
      >
        Intro prompt
      </button>
      <button
        @click="
          $store.state.currentState = $uiState.PLAYING;
          $store.state.currentGameState = $gameState.FISHING_INTRO;
        "
      >
        Koi prompt
      </button>
      <button
        @click="
          $store.state.currentState = $uiState.PLAYING;
          $store.state.currentGameState = $gameState.FINISHED_INTRO;
        "
      >
        End prompt
      </button>
      <button
        @click="
          $store.state.currentState = $uiState.FINISHED;
          $store.state.currentGameState = $gameState.FINISHED;
        "
      >
        Finished screen
      </button>
      <button
        @click="
          $store.state.currentState = -1;
          $store.state.currentGameState = -1;
          showGame = true;
          setupScene();
        "
      >
        -- HIDE UI --
      </button>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import CookieBanner from "@/components/CookieBanner.vue";
import UserInterface from "@/components/UserInterface.vue";
import * as CatQuest from "./renderer/js/CatQuest.js";

export default {
  name: "App",
  components: { CookieBanner, UserInterface },
  props: [
    "site",
    "sites",
    "videos",
    "translations",
    "generaltranslations",
    "primaryquestions",
    "secondaryquestions",
  ],
  data() {
    return {
      CatQuest: CatQuest,
      showGame: false,
      currentStep: 0,
      showCookieForm: localStorage.getItem("cookieConsent") ? false : true,
      selectedCountry: localStorage.getItem("country")
        ? localStorage.getItem("country")
        : null,
      primaryQuestionSet: null,
      secondaryQuestionSet: null,
      // TODO: Temporary vars
      production: process.env.NODE_ENV === "production",
      webglDev: process.env.VUE_APP_DEV_ENV,
    };
  },
  created() {
    // Initial setup
    this.setupGlobals();
    this.checkLanguage();
    this.setupWindowProperties();

    if (this.webglDev) {
      this.$store.state.currentState = -1;
      this.$store.state.currentGameState = -1;
    }
  },
  computed: {
    currentState() {
      return this.$store.state.currentState;
    },
    currentGameState() {
      return this.$store.state.currentGameState;
    },
    // TODO: Temporary logging
    stateToString() {
      if (this.$store.state.currentState === 0) return "INIT";
      if (this.$store.state.currentState === 1) return "LOADING";
      if (this.$store.state.currentState === 2) return "QUIZ";
      if (this.$store.state.currentState === 3) return "PLAYING";
      if (this.$store.state.currentState === 4) return "FINISHED";
      return "OUT OF BOUNDS";
    },
    gameStateToString() {
      if (this.$store.state.currentGameState === 0) return "INTRO";
      if (this.$store.state.currentGameState === 1) return "WALKING";
      if (this.$store.state.currentGameState === 2) return "FISHING_INTRO";
      if (this.$store.state.currentGameState === 3) return "FISHING";
      if (this.$store.state.currentGameState === 4) return "FISHING_END";
      if (this.$store.state.currentGameState === 5) return "FINISHED_INTRO";
      if (this.$store.state.currentGameState === 6) return "FINISHED";
      if (this.$store.state.currentGameState === 7) return "PAUSED";
      return "OUT OF BOUNDS";
    },
  },
  watch: {
    // TODO: Temporary logging
    currentState() {
      if (!this.production) {
        console.log("UI State: ", this.stateToString);
        console.log("Game State: ", this.gameStateToString);
      }
    },
    currentGameState() {
      if (!this.production) {
        console.log("UI State: ", this.stateToString);
        console.log("Game State: ", this.gameStateToString);
      }
    },
  },
  methods: {
    setupGlobals() {
      // Assign Craft CMS variables to global variables
      Vue.prototype.$t = this.translations;
      Vue.prototype.$genT = this.generaltranslations;
      Vue.prototype.$sites = this.sites;
      Vue.prototype.$site = this.site;
      Vue.prototype.$videos = JSON.parse(this.videos);

      // TODO: Temporary regarding WebGL development
      Vue.prototype.$trans = (string) => {
        if (!this.webglDev) {
          return this.$t[string];
        } else {
          return "testvariabele";
        }
      };

      Vue.prototype.$genTrans = (string) => {
        if (!this.webglDev) {
          return this.$genT[string];
        } else {
          return "testvariabele";
        }
      };

      Vue.prototype.$sitesAvailable = () => {
        if (!this.webglDev) {
          return this.$sites;
        } else {
          return [];
        }
      };

      Vue.prototype.$currentSite = () => {
        if (!this.webglDev) {
          return this.$site;
        } else {
          return [];
        }
      };

      if (this.primaryquestions) {
        const primarySet = this.setupQuestions([1, 2, 3, 4]);
        this.primaryQuestionSet = this.primaryquestions.filter(
          (q) => parseInt(q.questionsSet.value) == primarySet
        );
      }

      if (this.secondaryquestions) {
        const secondarySet = this.setupQuestions([5, 6, 7, 8]);
        this.secondaryQuestionSet = this.secondaryquestions.filter(
          (q) => parseInt(q.questionsSet.value) == secondarySet
        );
      }

      // TODO: Temporary Get Questions sets
      Vue.prototype.$primaryQuestionSet = () => {
        if (!this.webglDev) {
          return this.primaryQuestionSet;
        } else {
          return null;
        }
      };

      Vue.prototype.$secondaryQuestionSet = () => {
        if (!this.webglDev) {
          return this.secondaryQuestionSet;
        } else {
          return null;
        }
      };
    },
    checkLanguage() {
      if (this.selectedCountry) {
        const isCurrent = this.$currentSite().handle === this.selectedCountry;
        const isAvailable = this.$sitesAvailable()
          .map((s) => s.handle)
          .includes(this.selectedCountry);
        if (!isCurrent && isAvailable) {
          window.location.href = `/${this.selectedCountry}`;
        } else if (!isAvailable) {
          localStorage.removeItem("country");
        }
      }
    },
    setupWindowProperties() {
      window.soundState = false;
    },
    setupQuestions(range) {
      let playedSets = JSON.parse(localStorage.getItem("playedSets"));
      let availableSets = range;
      if (playedSets) {
        // Get the sets possible to select from, so not included in playes cookie
        availableSets = range.filter((set) => !playedSets.includes(set));
      }

      if (availableSets.length > 0) {
        // Select a random availble set
        const newSet =
          availableSets[Math.floor(Math.random() * availableSets.length)];

        // Add new set to localstorage
        if (playedSets) {
          playedSets.push(newSet);
        } else {
          playedSets = [];
          playedSets.push(newSet);
        }
        localStorage.setItem("playedSets", JSON.stringify(playedSets));

        return newSet;
      } else {
        localStorage.setItem(
          "playedSets",
          JSON.stringify(playedSets.filter((s) => !range.includes(s)))
        );
        return this.setupQuestions(range);
      }
    },
    setupScene() {
      try {
        let sceneCanvas = document.getElementById("canvas");
        CatQuest.setup(sceneCanvas);
        CatQuest.start();
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style lang="scss">
#app {
  overflow: hidden;
}

#canvas {
  position: fixed;
  z-index: 1;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  background: $color-green--primary;
  opacity: 0;
  transition: all $basic-transition-200;

  &.game--animate {
    opacity: 1;
  }
}

// Layer setup
.cookie-banner {
  z-index: 12;
}

.ui__element {
  z-index: 11;
}

.screen__rotate {
  z-index: 10 !important;
}

.ui__koi {
  z-index: 9;
}

.screen,
.prompt {
  z-index: 9;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

// Temp
.temp {
  position: fixed;
  z-index: 1000;
  flex-direction: column;
  display: flex;
  bottom: 0;
  right: 0;

  @include for-tablet-landscape-up {
    display: flex;
  }
}

.temp-button {
  position: fixed;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10000;
}

.lil-gui,
#statsContainer {
  opacity: 0.6;
}

.slide-cookie-down-enter-active,
.slide-cookie-down-leave-active {
  transition: transform 0.3s ease-in;
  z-index: 12;
}

.slide-cookie-down-enter,
.slide-cookie-down-leave-to {
  transform: translateY(100%);
  z-index: 12;
}
</style>

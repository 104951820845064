<template>
  <div class="number__row">
    <span
      v-for="(q, index) in questions"
      :key="index"
      :class="`number ${
        active === index + 1
          ? 'active'
          : active > index + 1
          ? 'before'
          : active < index + 1
          ? 'after'
          : ''
      } ${index < active - 4 || index > active + 2 ? 'hidden' : ''}`"
      :data-index="index"
    >
      {{ index + 1 }}
    </span>
  </div>
</template>

<script>
import { isTouchDevice } from "@/helpers";

export default {
  name: "NumberProgress",
  props: ["active"],
  data() {
    return {
      questions: this.$primaryQuestionSet().concat(
        this.$secondaryQuestionSet()
      ),
    };
  },
  mounted() {
    let befores = document.querySelectorAll(".before");
    let afters = document.querySelectorAll(".after");
    const mobileSpacing = 2;
    const dekstopSpacing = 3.2;
    const ultraSpacing = 32;
    let baseSpacing = isTouchDevice()
      ? mobileSpacing
      : window.innerWidth < 2200
      ? dekstopSpacing
      : null;

    befores.forEach((el, index) => {
      let multiplyer = befores.length - index - 1;
      if (baseSpacing) {
        let space = baseSpacing * (multiplyer + 1) + multiplyer * 1;
        el.style.left = `-${space}rem`;
      } else {
        let space = ultraSpacing * (multiplyer + 1) + multiplyer * 1;
        el.style.left = `calc(${space}vw * 100 / 1440 * -1)`;
      }
    });

    afters.forEach((el, index) => {
      if (baseSpacing) {
        let space = baseSpacing * (index + 1) + index * 1;
        el.style.left = `${space}rem`;
      } else {
        let space = ultraSpacing * (index + 1) + index * 1;
        el.style.left = `calc(${space}vw * 100 / 1440)`;
      }
    });
  },
};
</script>

<style lang="scss" scoped>
.number {
  font-weight: 600;

  &__row {
    display: flex;
    align-items: center;
    flex: 1;

    @include for-tablet-landscape-up {
      flex: unset;
    }
  }

  &:not(.active) {
    width: 0;
    display: grid;
    place-content: center;
    position: relative;

    &.after {
      opacity: 0.5;
    }
  }

  &.active {
    display: grid;
    place-content: center;
    background-image: url("/assets/img/diamond.svg");
    background-size: cover;
    height: 5rem;
    width: 5rem;
    position: relative;
    background-repeat: no-repeat;

    &::after {
      content: "";
      position: absolute;
      bottom: -2.4rem;
      background-image: url("/assets/img/diamond--shadow.png");
      width: 150%;
      height: 1.4rem;
      background-size: 100% 100%;
      background-repeat: no-repeat;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  @include for-giant-desktop-up {
    &.active {
      height: rs(50);
      width: rs(50);

      &::after {
        bottom: rs(-24);
        height: rs(14);
      }
    }
  }
}
</style>

<template>
  <div class="text-input">
    <label :for="name" v-if="label">{{ label }}</label>
    <input
      :class="`${error ? 'invalid' : ''}`"
      :name="name"
      :type="type"
      :placeholder="placeholder ? placeholder : ''"
      v-model="input"
      @input="handleInput"
      @focus="$emit('clear-error')"
    />
    <transition name="slide-down">
      <span class="error" v-if="typeof error === 'string'">{{ error }}</span>
    </transition>
  </div>
</template>

<script>
export default {
  props: {
    label: String,
    name: String,
    value: String,
    placeholder: String,
    error: { default: false },
    type: { default: "text" },
  },
  data() {
    return {
      input: this.value,
    };
  },
  methods: {
    handleInput() {
      this.$emit("input", this.input);
    },
  },
};
</script>

<style scoped lang="scss">
.text-input {
  margin: 2rem 0 0 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  label {
    margin: 0;
    font-weight: 600;
    @include fontSize(1.2rem, 1.2, 1.2rem);
    color: $color-text--inverse;
    text-transform: uppercase;
  }

  input {
    @include fontSize(1.8rem, 1.8, 1.8rem);

    width: 100%;
    position: relative;

    background: #ffffff;
    border-radius: 0.8rem;
    border: none;

    min-height: 5rem;
    min-width: 30rem;
    padding: 0.5rem 1.2rem;

    margin-top: 0.7rem;

    &.invalid {
      box-shadow: 0 0 0 0.3rem $color-text--invalid;
    }

    &:focus {
      outline: none;
      box-shadow: 0 0 0 0.3rem $color-blue--secondary;
    }

    &::placeholder {
      opacity: 0.3;
    }

    @include for-tablet-landscape-up {
      width: 100%;
    }
  }

  .error {
    font-weight: 600;
    @include fontSize(1.2rem, 1.2, 1.2rem);
    margin: 1rem 0 0 0;
    color: $color-text--invalid;
  }

  .slide-down-enter-active,
  .slide-down-leave-active {
    transition: all $basic-transition-300;
  }

  .slide-down-enter,
  .slide-down-leave-to {
    opacity: 0;
    transform: translateY(-50%);
  }

  @include for-tablet-landscape-up {
    margin: rs(20) 0 0 0;

    label {
      font-size: rs(10) !important;
    }

    input {
      font-size: rs(16) !important;
      border-radius: rs(8);
      min-height: rs(45);
      min-width: rs(300);
      padding: rs(5) rs(12);
      margin-top: rs(7);

      &.invalid {
        box-shadow: 0 0 0 rs(3) $color-text--invalid;
      }

      &:focus {
        box-shadow: 0 0 0 rs(3) $color-blue--secondary;
      }

      &::placeholder {
        font-size: rs(16) !important;
      }
    }

    .error {
      font-size: rs(12) !important;
      margin: rs(10) 0 0 0;
    }
  }
}
</style>

<template>
  <div class="question-progress">
    <div class="question-progress__title">
      <span>{{ $trans("gameProgressBarTitle") }}</span>
      <span>{{ questionCount }} / {{ questionCountTotal }}</span>
    </div>
    <div class="question-progress__bar">
      <div
        class="question-progress__bar--inner"
        :style="`width: ${percentage}%;`"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BarProgress",
  data() {
    return {
      questionCount: this.$store.state.questionAnswers.length,
      questionCountTotal: this.$primaryQuestionSet().concat(
        this.$secondaryQuestionSet()
      ).length,
    };
  },
  computed: {
    percentage() {
      return (this.questionCount / this.questionCountTotal) * 100;
    },
  },
};
</script>

<style lang="scss" scoped>
.question-progress {
  max-width: calc(100% - 2.4rem - 2.8rem - 1rem);

  @include for-tablet-landscape-up {
    min-width: 30rem;
    width: 25%;
  }

  &__title {
    display: flex;
    justify-content: space-between;

    padding: 2rem 0.3rem 1rem 0.3rem;

    @include for-tablet-landscape-up {
      padding: 0.8rem 1.2rem 1.6rem 1.2rem;
    }

    * {
      @include fontSize(0.9rem, 1.4, 1.4rem, 1);
      font-weight: bold;
      text-transform: uppercase;
      color: $color-text--inverse;
    }

    & :first-child {
      margin-right: 2rem;
    }

    & :last-child {
      white-space: nowrap;
      display: flex;
      align-items: flex-end;
    }
  }

  &__bar {
    width: 100%;
    height: 0.2rem;
    background: rgba($color-black, 0.25);

    &--inner {
      width: 0%;
      height: 0.2rem;
      background: $color-white;
    }
  }

  @include for-giant-desktop-up {
    min-width: rs(300);

    &__title {
      padding: rs(12) rs(12) rs(16) rs(12);

      * {
        font-size: rs(14) !important;
      }

      & :first-child {
        margin-right: rs(20);
      }
    }

    &__bar {
      height: rs(2);

      &--inner {
        height: rs(2);
      }
    }
  }
}
</style>

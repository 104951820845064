import { Sounds } from "@/constants";

export function loadAll() {
  Sounds.forEach((sound) => {
    sound.src.load();
  });
  return 100;
}

export function load(key) {
  Sounds.find((x) => x.key === key).src.load();
}

export function play(key, sprite = null) {
  if (window.soundState) {
    if (sprite) {
      Sounds.find((x) => x.key === key).src.play(sprite);
    } else {
      Sounds.find((x) => x.key === key).src.play();
    }
  }
}

export function stop(key) {
  if (window.soundState) {
    Sounds.find((x) => x.key === key).src.stop();
  }
}

export function setLoop(key) {
  if (window.soundState) {
    Sounds.find((x) => x.key === key).src.loop(true);
  }
}

export function fadeIn(key, timing) {
  if (window.soundState) {
    let sound = Sounds.find((x) => x.key === key);
    sound.src.volume(0);
    sound.src.fade(0, sound.initVolume, timing);
  }
}

export function fadeOut(key, timing) {
  if (window.soundState) {
    let sound = Sounds.find((x) => x.key === key);
    sound.src.fade(sound.initVolume, 0, timing);
  }
}

export function fadeOutStop(key, timing) {
  if (window.soundState) {
    let sound = Sounds.find((x) => x.key === key);
    sound.src.fade(sound.initVolume, 0, timing);
    setTimeout(() => {
      sound.src.stop();
    }, timing);
  }
}

export function playRandomMeow(volume = 1) {
  if (window.soundState) {
    const meows = Sounds.filter((x) => x.key.includes("meow"));
    let meow = meows[Math.floor(Math.random() * meows.length)];
    meow.src.volume(volume);
    meow.src.play();
  }
}

export function increaseTo(key, options) {
  if (window.soundState) {
    let sound = Sounds.find((x) => x.key === key);
    if (options.rate) {
      sound.src.rate(options.rate);
    }

    if (options.volume) {
      sound.src.volume(options.volume);
    }
  }
}



export const name = 'Utils';


export function ofMap(value, inputMin, inputMax, outputMin, outputMax, clamp) {

    var outVal = ((value - inputMin) / (inputMax - inputMin) * (outputMax - outputMin) + outputMin);

    if (clamp) {
        if (outputMax < outputMin) {
            if (outVal < outputMax) outVal = outputMax;
            else if (outVal > outputMin) outVal = outputMin;
        } else {
            if (outVal > outputMax) outVal = outputMax;
            else if (outVal < outputMin) outVal = outputMin;
        }
    }
    return outVal;

}


export function TriggerEvent(name,data) {
  //  console.log("sending event from webgl");
  //  console.log("event name " + name);
  //  console.log("event data " + (JSON.stringify(data)));
  //  console.log("-------------------");

    const openQuizEvent = new CustomEvent(name,data);
    document.dispatchEvent(openQuizEvent);
}

export function debug2(value) {
    const debugText = document.getElementById('debug2');
    debugText.innerHTML = String(value);
}